import { Group } from '@mantine/core';
import type { GroupProps } from '@mantine/core';

import { Link } from '@apple/ui/link';

import classes from './topbar-nav-menu.module.css';
import type { MenuItem } from './menu-item';

export interface NavMenuProps extends GroupProps {
	items: MenuItem[];
}

export function TopbarNavMenu({ items, ...props }: NavMenuProps) {
	return (
		<Group justify='start' align='center' gap='xl' {...props}>
			{items.map(({ icon: _, label, to }, i) => (
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				<Link key={i} className={classes.routerLink} td='none' label={label} to={to} />
			))}
		</Group>
	);
}
