import React, { memo, useCallback, useState } from 'react';
import { Button, Tooltip } from '@mantine/core';
import type { ButtonProps } from '@mantine/core';

export interface FilterToggleButtonProps extends ButtonProps {
	switchText: string;
	onChange: (state: boolean) => void;
	targetIcon: React.ReactNode;
	testId?: string | undefined;
}

export const FilterToggleButton = memo<FilterToggleButtonProps>(
	({ switchText, onChange, targetIcon, testId, ...others }: FilterToggleButtonProps) => {
		const [state, setState] = useState(false);

		const handleClick = useCallback(() => {
			setState(!state);
			onChange(!state);
		}, [onChange, state]);

		return (
			<Tooltip label={switchText}>
				<Button
					variant={state ? 'outline' : 'transparent'}
					onClick={handleClick}
					data-testid={testId}
					{...others}
				>
					{targetIcon}
				</Button>
			</Tooltip>
		);
	},
);
