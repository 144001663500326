import { generateColors } from '@mantine/colors-generator';
import { Container, createTheme, Input, MultiSelect, rem, Table } from '@mantine/core';
import { DateInput, DatePickerInput } from '@mantine/dates';
import cx from 'clsx';
import type {
	AppShellNavbarConfiguration,
	CSSProperties,
	CSSVariablesResolver,
	MantineThemeOverride,
} from '@mantine/core';
import type { TFunction } from 'i18next';

import { fonts } from '@apple/assets';

import tableClasses from './apple-table.module.css';
import classes from './apple-theme.module.css';

declare module '@mantine/core' {
	interface MantineThemeOther {
		page: {
			minWidth: CSSProperties['minWidth'];
		};
		headerHeight: string;
		footerHeight: string;
		headerBackgroundColor: CSSProperties['backgroundColor'];
		headerBackgroundColorDark: CSSProperties['backgroundColor'];
		headerBackdropFilter: CSSProperties['backdropFilter'];
		controlAccentColor: CSSProperties['color'];

		primarySidebar: {
			width: AppShellNavbarConfiguration['width'];
			rowHeight: {
				sm: CSSProperties['height'];
				md: CSSProperties['height'];
				lg: CSSProperties['height'];
			};
			iconSize: {
				sm: CSSProperties['height'];
				md: CSSProperties['height'];
				lg: CSSProperties['height'];
			};
			textSize: {
				sm: CSSProperties['fontSize'];
				md: CSSProperties['fontSize'];
				lg: CSSProperties['fontSize'];
			};
			cellSpacing: {
				horizontal: CSSProperties['paddingLeft'];
				vertical: CSSProperties['paddingTop'];
			};
		};

		getOrderStatusColor: (
			status: string | undefined,
			pendingCancellation: boolean,
			t: TFunction<'order'>,
		) => CSSProperties['color'];
	}
}

// + Finish setting up theme
// https://mantine.dev/theming/default-theme/
// https://developer.apple.com/design/human-interface-guidelines/color#Specifications
// https://developer.apple.com/design/human-interface-guidelines/dark-mode
export const appleThemeOverrides: MantineThemeOverride = createTheme({
	// "scale": 1,
	// "fontSmoothing": true,
	// "focusRing": "auto",
	// "white": "#fff",
	// "black": "#000",
	// https://www.figma.com/design/FECashBwWUHKPEu1R1Nyxh/macOS-Monterey-UI-Kit-for-Figma-(Community)?node-id=4-745&m=dev
	colors: {
		//   "dark": [ "#C1C2C5", "#A6A7AB", "#909296", "#5c5f66", "#373A40", "#2C2E33", "#25262b", "#1A1B1E", "#141517", "#101113" ],
		//   "gray": [ "#f8f9fa", "#f1f3f5", "#e9ecef", "#dee2e6", "#ced4da", "#adb5bd", "#868e96", "#495057", "#343a40", "#212529" ],
		//   "red": [ "#fff5f5", "#ffe3e3", "#ffc9c9", "#ffa8a8", "#ff8787", "#ff6b6b", "#fa5252", "#f03e3e", "#e03131", "#c92a2a" ],
		//   "pink": [ "#fff0f6", "#ffdeeb", "#fcc2d7", "#faa2c1", "#f783ac", "#f06595", "#e64980", "#d6336c", "#c2255c", "#a61e4d" ],
		//   "grape": [ "#f8f0fc", "#f3d9fa", "#eebefa", "#e599f7", "#da77f2", "#cc5de8", "#be4bdb", "#ae3ec9", "#9c36b5", "#862e9c" ],
		//   "violet": [ "#f3f0ff", "#e5dbff", "#d0bfff", "#b197fc", "#9775fa", "#845ef7", "#7950f2", "#7048e8", "#6741d9", "#5f3dc4" ],
		//   "indigo": [ "#edf2ff", "#dbe4ff", "#bac8ff", "#91a7ff", "#748ffc", "#5c7cfa", "#4c6ef5", "#4263eb", "#3b5bdb", "#364fc7" ],
		blue: generateColors('#007AFF'),
		cyan: generateColors('#55BEF0'),
		green: generateColors('#28CD41'),
		indigo: generateColors('#5856D6'),
		teal: generateColors('#59ADC4'),
		orange: generateColors('#FF9500'),
		pink: generateColors('#FF2D55'),
		purple: generateColors('#AF52DE'),
		red: generateColors('#FF3B30'),
		yellow: generateColors('#FFCC00'),
		//Based on apple.com Accessories background color https://v7.mantine.dev/colors-generator?color=F5F5F7
		// gray: [...generateColors('#8E8E93'), 'rgb(28, 28, 30)'],
		gray: [
			'rgb(242, 242, 247)',
			'rgb(229, 229, 234)',
			'rgb(209, 209, 214)',
			'rgb(199, 199, 204)',
			'rgb(174, 174, 178)',
			'rgb(142, 142, 147)',
			'rgb(99, 99, 102)',
			'rgb(72, 72, 74)',
			'rgb(58, 58, 60)',
			'rgb(44, 44, 46)',
			'rgb(28, 28, 30)',
		],
		//   "cyan": [ "#e3fafc", "#c5f6fa", "#99e9f2", "#66d9e8", "#3bc9db", "#22b8cf", "#15aabf", "#1098ad", "#0c8599", "#0b7285" ],
		//   "teal": [ "#e6fcf5", "#c3fae8", "#96f2d7", "#63e6be", "#38d9a9", "#20c997", "#12b886", "#0ca678", "#099268", "#087f5b" ],
		//   "green": [ "#ebfbee", "#d3f9d8", "#b2f2bb", "#8ce99a", "#69db7c", "#51cf66", "#40c057", "#37b24d", "#2f9e44", "#2b8a3e" ],
		//   "lime": [ "#f4fce3", "#e9fac8", "#d8f5a2", "#c0eb75", "#a9e34b", "#94d82d", "#82c91e", "#74b816", "#66a80f", "#5c940d" ],
		//   "yellow": [ "#fff9db", "#fff3bf", "#ffec99", "#ffe066", "#ffd43b", "#fcc419", "#fab005", "#f59f00", "#f08c00", "#e67700" ],
		//   "orange": [ "#fff4e6", "#ffe8cc", "#ffd8a8", "#ffc078", "#ffa94d", "#ff922b", "#fd7e14", "#f76707", "#e8590c", "#d9480f" ]
	},
	// primaryShade: {
	// 	light: 5,
	// 	dark: 7,
	// },
	// primaryColor: 'blue',
	fontFamily: `SF Pro Text, SF Pro Icons, -apple-system, BlinkMacSystemFont, ${fonts.myriadPro.fontFamily}, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji`,
	// "fontFamilyMonospace": "ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace",
	// "respectReducedMotion": false,

	// Controls the default cursor type for interactive elements, that do not have
	// cursor: pointer styles by default. For example, `Checkbox` and `NativeSelect`.
	// "cursorType": "default",
	cursorType: 'pointer',
	// "defaultGradient": {
	//   "from": "blue",
	//   "to": "cyan",
	//   "deg": 45
	// },
	// "defaultRadius": "sm",
	defaultRadius: 'md',
	// "activeClassName": "mantine-active",
	// "focusClassName": "",
	// "headings": {
	//   "fontFamily": "-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji",
	//   "fontWeight": "700",
	//   "sizes": {
	// 	"h1": {
	// 	  "fontSize": "calc(2.125rem * var(--mantine-scale))",
	// 	  "lineHeight": "1.3"
	// 	},
	// 	"h2": {
	// 	  "fontSize": "calc(1.625rem * var(--mantine-scale))",
	// 	  "lineHeight": "1.35"
	// 	},
	// 	"h3": {
	// 	  "fontSize": "calc(1.375rem * var(--mantine-scale))",
	// 	  "lineHeight": "1.4"
	// 	},
	// 	"h4": {
	// 	  "fontSize": "calc(1.125rem * var(--mantine-scale))",
	// 	  "lineHeight": "1.45"
	// 	},
	// 	"h5": {
	// 	  "fontSize": "calc(1rem * var(--mantine-scale))",
	// 	  "lineHeight": "1.5"
	// 	},
	// 	"h6": {
	// 	  "fontSize": "calc(0.875rem * var(--mantine-scale))",
	// 	  "lineHeight": "1.5"
	// 	}
	//   }
	// },
	fontSizes: {
		xs: 'calc(0.6875rem * var(--mantine-scale))',
		sm: 'calc(0.875rem * var(--mantine-scale))',
		md: 'calc(1rem * var(--mantine-scale))',
		lg: 'calc(1.125rem * var(--mantine-scale))',
		xl: 'calc(1.25rem * var(--mantine-scale))',
	},
	lineHeights: {
		xs: '1.3',
		sm: '1.35',
		md: '1.4',
		lg: '1.45',
		xl: '1.5',
	},
	// "radius": {
	//   "xs": "calc(0.125rem * var(--mantine-scale))",
	//   "sm": "calc(0.25rem * var(--mantine-scale))",
	//   "md": "calc(0.5rem * var(--mantine-scale))",
	//   "lg": "calc(1rem * var(--mantine-scale))",
	//   "xl": "calc(2rem * var(--mantine-scale))"
	// },
	// "spacing": {
	//   "xs": "calc(0.625rem * var(--mantine-scale))",
	//   "sm": "calc(0.75rem * var(--mantine-scale))",
	//   "md": "calc(1rem * var(--mantine-scale))",
	//   "lg": "calc(1.25rem * var(--mantine-scale))",
	//   "xl": "calc(2rem * var(--mantine-scale))"
	// },
	// TODO: I don't think the postcss breakpoints are working because customizing these causes weird behaviors between the modified breakpoints...
	// breakpoints: {
	// 	xs: '36em',
	// 	sm: '48em',
	// 	md: '64em', // Default: 62em
	// 	lg: '75em',
	// 	xl: '90em',
	// },
	// "shadows": {
	//   "xs": "0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05), 0 calc(0.0625rem * var(--mantine-scale)) calc(0.125rem * var(--mantine-scale)) rgba(0, 0, 0, 0.1)",
	//   "sm": "0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 calc(0.625rem * var(--mantine-scale)) calc(0.9375rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale)), rgba(0, 0, 0, 0.04) 0 calc(0.4375rem * var(--mantine-scale)) calc(0.4375rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale))",
	//   "md": "0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 calc(1.25rem * var(--mantine-scale)) calc(1.5625rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale)), rgba(0, 0, 0, 0.04) 0 calc(0.625rem * var(--mantine-scale)) calc(0.625rem * var(--mantine-scale)) calc(-0.3125rem * var(--mantine-scale))",
	//   "lg": "0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 calc(1.75rem * var(--mantine-scale)) calc(1.4375rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale)), rgba(0, 0, 0, 0.04) 0 calc(0.75rem * var(--mantine-scale)) calc(0.75rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale))",
	//   "xl": "0 calc(0.0625rem * var(--mantine-scale)) calc(0.1875rem * var(--mantine-scale)) rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05) 0 calc(2.25rem * var(--mantine-scale)) calc(1.75rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale)), rgba(0, 0, 0, 0.04) 0 calc(1.0625rem * var(--mantine-scale)) calc(1.0625rem * var(--mantine-scale)) calc(-0.4375rem * var(--mantine-scale))"
	// },
	other: {
		page: {
			minWidth: rem(380),
		},
		headerHeight: rem(44),
		footerHeight: rem(84),
		headerForegroundColor: 'rgba(255, 255, 255, .8)',
		headerForegroundHoverColor: 'rgba(255, 255, 255, 1)',
		headerBackgroundColor: 'rgba(22, 22, 23, .8)',
		headerBackgroundColorDark: 'rgba(0, 0, 0, .7)',
		headerBackdropFilter: 'saturate(180%) blur(30px)',
		controlAccentColor: 'rgb(10, 132, 255)',
		primarySidebar: {
			width: 260,
			rowHeight: {
				sm: '24pt',
				md: '28pt',
				lg: '32pt',
			},
			iconSize: {
				sm: 16,
				md: 20,
				lg: 24,
			},
			textSize: {
				sm: '11pt',
				md: '13pt',
				lg: '15pt',
			},
			cellSpacing: {
				horizontal: '17pt',
				vertical: 0,
			},
		},
		getOrderStatusColor: (status, pendingCancellation, t) => {
			if (pendingCancellation) {
				return 'light-dark(var(--mantine-color-yellow-7), var(--mantine-color-yellow-5))';
			}

			// This is a bit of a hack since the backend doesn't return the status as an enum...
			switch (status) {
				case t('orderStatus.inProcess'):
				case t('orderStatus.shipped'):
					return 'light-dark(var(--mantine-color-green-7), var(--mantine-color-green-5))';
				case t('orderStatus.rejected'):
					return 'light-dark(var(--mantine-color-red-7), var(--mantine-color-red-5))';
				case t('orderStatus.orderReview'):
					return 'light-dark(var(--mantine-color-yellow-8), var(--mantine-color-yellow-5))';
				case t('orderStatus.cancelled'):
					return 'light-dark(var(--mantine-color-gray-7), var(--mantine-color-gray-5))';
				case t('orderStatus.backordered'):
					return 'light-dark(var(--mantine-color-red-7), var(--mantine-color-red-5))';
				default:
					return 'light-dark(var(--mantine-color-blue-7), var(--mantine-color-blue-5))';
			}
		},
	},
	components: {
		Container: Container.extend({
			classNames: (_, { size }) => ({
				root: cx({ [classes.responsiveContainer ?? '']: size === 'responsive' }),
			}),
		}),
		Input: Input.extend({
			vars: () => ({
				wrapper: {
					'--input-height-xs': rem(16),
					'--input-height-sm': rem(24),
					'--input-height-md': rem(28),
					'--input-height-lg': rem(32),
					'--input-height-xl': rem(36),
					'--input-radius': rem(6),
				},
			}),
		}),
		MultiSelect: MultiSelect.extend({
			vars: () => ({
				wrapper: {
					'--input-size': 'var(--input-height)',
					'--input-padding-y': '0',
				},
			}),
		}),

		DateInput: DateInput.extend({
			styles: {
				input: {
					height: 'var(--input-size)',
				},
			},
		}),

		DatePickerInput: DatePickerInput.extend({
			styles: {
				input: {
					height: 'var(--input-size)',
				},
			},
		}),

		Table: Table.extend({
			classNames: tableClasses,
		}),

		// Popover: Popover.extend({
		// 	vars: (theme, props) => {
		// 		return {
		// 			//FIXME: The popover background color customization is not working
		// 			dropdown: {
		// 				'--_popover-bg':
		// 					'light-dark(var(--mantine-color-white), var(--mantine-color-dark-8))',
		// 			} as unknown as any,
		// 		};
		// 	},
		// }),
		// AppShellHeader: AppShellHeader.extend({
		// 	styles: {
		// 		header: {
		// 			backgroundColor: 'var(--apple-header-background-color)',
		// 			color: 'var(--apple-header-foreground-color)',
		// 			backdropFilter: 'var(--apple-header-backdrop-filter)',
		// 			zIndex: 'var(--app-shell-header-z-index)',
		// 		},
		// 	},
		// 	vars: theme => ({
		// 		'--apple-header-background-color': theme.other.headerBackgroundColorDark,
		// 	}),
		// }),
	},
});

export const cssVariablesResolver: CSSVariablesResolver = theme => ({
	variables: {
		'--app-shell-header-height': `calc(${theme.other.headerHeight} * var(--mantine-scale))`,
		'--app-shell-footer-height': `calc(${theme.other.footerHeight} * var(--mantine-scale))`,
		//'--mantine-hero-height': theme.other.heroHeight,
		'--apple-header-foreground-color': theme.other.headerForegroundColor as string,
		'--apple-header-foreground-hover-color': theme.other.headerForegroundHoverColor as string,
		'--apple-header-background-color': theme.other.headerBackgroundColor as string,
		'--apple-header-background-color-dark': theme.other.headerBackgroundColorDark as string,
		'--apple-header-backdrop-filter': theme.other.headerBackdropFilter as string,
		'--app-shell-header-z-index': '9999',
		'--apple-backdrop-filter': theme.other.headerBackdropFilter as string,
		'--apple-dark-background-color': `light-dark(rgba(0, 0, 0, .1), rgba(255, 255, 255, .1))`,
		'--apple-dark-backdrop-filter': `saturate(180%) blur(15px)`,
	},
	light: {
		//'--mantine-color-deep-orange': theme.other.deepOrangeLight,
	},
	dark: {
		//'--mantine-color-deep-orange': theme.other.deepOrangeDark,
	},
});
