import { useState } from 'react';
import { useForm } from '@mantine/form';
import { getLogger } from 'loglevel';
import type { UseFormInput, UseFormReturnType } from '@mantine/form';
import type { ReactElement } from 'react';

import { useTranslation } from '@apple/lib/i18next';
import { ServerError, ServerValidationError } from '@apple/utils/api';
import type { AllKeysAreStrings } from '@apple/utils/type';
import type { FormErrors } from '@apple/utils/validation';

import { FormError } from '../FormError';
import { SubmitButton } from '../SubmitButton';
import type { SubmitButtonProps } from '../SubmitButton';

const log = getLogger('validation');

export type ValidatedFormProps<T extends AllKeysAreStrings<T>> = UseFormInput<T> & {
	/** A unique name for the form */
	name: string;
	onValidSubmit: (data: T) => Promise<void>;
	fallbackErrorMessage?: string;
	ignoreModelErrorParent?: boolean;
	children: (props: {
		isSubmitting: boolean;
		form: UseFormReturnType<T>;
		formErrors: FormErrors | undefined;
		renderFormErrors: () => ReactElement;
		renderSubmitButton: (
			props: Omit<SubmitButtonProps, 'isSubmitting' | 'form'>,
		) => ReactElement<SubmitButtonProps>;
	}) => JSX.Element;
};

export function ValidatedForm<T extends AllKeysAreStrings<T>>({
	name,
	initialValues,
	onValidSubmit,
	fallbackErrorMessage,
	validate,
	children,
	ignoreModelErrorParent = false,
	...useFormProps
}: ValidatedFormProps<T>) {
	const { t } = useTranslation('auth');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [formErrors, setFormErrors] = useState<FormErrors>();

	const form = useForm<T>({
		validateInputOnChange: true,
		validateInputOnBlur: true,

		...useFormProps,
		name,
		initialValues,
		validate,
	});

	async function handleSubmit(data: T) {
		setIsSubmitting(true);

		try {
			log.info(`Validating '${name}' form:`, data);
			const validateResult = form.validate();
			if (validateResult.hasErrors) {
				log.warn('Form validation failed:', data, validateResult);
				return;
			}

			log.info(`Submitting '${name}' form:`, data);
			await onValidSubmit(data);
			setFormErrors(undefined);
		} catch (error) {
			log.warn('An error occurred while attempting to submit form:\n', error);

			if (error instanceof ServerValidationError) {
				if (error.problem.formErrors) {
					log.warn('Form level errors:', error.problem.formErrors?.length ?? 0);
					setFormErrors(error.problem.formErrors);
				}

				if (error.problem.fieldErrors) {
					const fieldErrors = Object.entries(error.problem.fieldErrors);

					log.warn('Field level errors:', fieldErrors.length);
					fieldErrors.forEach(([field, error]) => {
						form.setFieldError(transformServerValidationErrorField(field), error);
					});
				}
			} else if (error instanceof ServerError) {
				log.warn('Server error:', error.message);
				setFormErrors([error.message]);
			} else {
				log.error('Unknown error:', error);
				setFormErrors([fallbackErrorMessage ?? t('common:error.generic')]);
			}
		} finally {
			setIsSubmitting(false);
		}
	}

	function transformServerValidationErrorField(field: string): string {
		let fieldPath = field.split('.');
		if (fieldPath.length <= 1) {
			return field;
		}

		if (ignoreModelErrorParent) {
			fieldPath = fieldPath.slice(1);
		}

		// Nested fields are not camel-cased in model validation error response
		for (let i = 0; i < fieldPath.length; i++) {
			const value = fieldPath[i];
			if (value === undefined) {
				continue;
			}
			fieldPath[i] = value.charAt(0).toLowerCase() + value.slice(1);
		}

		// Pathing from the backend looks like an array indexer:
		//  list[0].field
		// in Mantine form it uses dots:
		//  list.0.field
		return fieldPath.join('.').replace(']', '').replace('[', '.');
	}

	return (
		<form onSubmit={form.onSubmit(x => void handleSubmit(x))}>
			{children({
				isSubmitting,
				form,
				formErrors,
				renderFormErrors: () => <FormError errors={formErrors ?? form.errors} />,
				renderSubmitButton: props => (
					<SubmitButton
						{...props}
						form={form as UseFormReturnType<unknown>}
						isSubmitting={isSubmitting}
					/>
				),
			})}
		</form>
	);
}
