import _ from 'lodash';
import { z } from 'zod';

import { httpProblemDetailsSchema } from '../api/models';
import type { HttpProblemDetails } from '../api/models';

export type ErrorMessage = z.infer<typeof errorMessageSchema>;

export type ErrorMessages = z.infer<typeof errorMessagesSchema>;

export type FormErrors = ErrorMessages;

export type FieldErrors<T = unknown> = { [FieldName in keyof T]: ErrorMessage | ErrorMessages };
// export const FieldErrorsSchema = () => z.record(
// 	z.lazy(() => z.union([errorMessageSchema, errorMessagesSchema]))
// );

export interface ValidationProblemDetails<T = unknown> extends HttpProblemDetails {
	readonly formErrors?: FormErrors;
	/** The validation errors. */
	readonly fieldErrors?: FieldErrors<T>;
}
/** See `Manzanita.Web\Infrastructure\Http\ApiControllerExtensions.cs` - `ModelValidationResponse` */
export type ModelStateErrorResponse<T = unknown> = {
	modelState: FieldErrors<T>;
};

export type AdditionalDataErrorResponse<T = unknown> = {
	additionalData: FieldErrors<T>;
};

export type CharacterWhitelist = z.infer<typeof characterWhitelistSchema>;
export type KeyValuePair = z.infer<typeof keyValuePairSchema>;
export type ValidationGroup = z.infer<typeof validationGroupSchema>;
export type ValidationRule = z.infer<typeof validationRuleSchema>;

export const errorMessageSchema = z.string();
export const errorMessagesSchema = z.array(errorMessageSchema);
export const formErrorsSchema = errorMessagesSchema;
export const keyValuePairSchema = z.object({
	Key: z.string(),
	Value: z.string(),
});
export const validationGroupSchema = z.object({
	allFieldsRequired: z.boolean(),
	fieldList: z.array(z.string()),
	maxLength: z.number().optional().nullable(),
	description: z.string().optional().nullable(),
});

export const validationRuleSchema = z.object({
	fieldId: z.string(),
	fieldName: z.string(),
	fieldLabel: z.string(),
	isRequired: z.boolean(),
	isVisible: z.boolean(),
	requiresServerValidation: z.boolean(),
	minLength: z.number().optional().nullable(),
	maxLength: z.number().optional().nullable(),
	minValue: z.number().optional().nullable(),
	maxValue: z.number().optional().nullable(),
	regex: z.string().optional().nullable(),
	checkWhitelist: z.boolean(),
	validationMessage: z.string().optional().nullable(),
	group: validationGroupSchema.optional().nullable(),
	groupId: z.number().optional().nullable(),
	acceptedValues: z.array(keyValuePairSchema).optional().nullable(),
});
export const validationFieldRuleResponseSchema = z.array(validationRuleSchema);
export const characterWhitelistSchema = z.array(z.string());
export const characterWhitelistResponseSchema = z.string().transform(x => _.toArray(x));

export type ValidationType =
	| 'AddEditShipToAddressDialog'
	// Address specific validation rules - not a form validation type in the backend
	| 'Address'
	| 'AnnouncementAdministration'
	| 'BannerAdministration'
	| 'ChangePassword'
	| 'CountryAdministration'
	| 'CurrencyConversionAdministration'
	| 'CustomerAdministration'
	| 'CustomerFulfillmentRule'
	| 'CustomerGroupAdministration'
	| 'DistroBulkOrderUpload'
	| 'EditEventDetails'
	| 'EditEventSaveConfirmation'
	| 'EventBulkManagement'
	| 'EventItemBulkManagement'
	| 'FaqAdministration'
	| 'FiscalCalendarAdministration'
	| 'MerchandiserAdministration'
	| 'OrderBulkImport'
	| 'OrderHeaderDetails'
	| 'PlantAdministration'
	| 'ProductAdministration'
	| 'ProductBulkAdministration'
	| 'ProductCategory'
	| 'ProductEventManagement'
	| 'ProductFulfillmentRule'
	| 'ProductLocalization'
	| 'ProductQuantityLimitByAccessType'
	| 'ProfileSettings'
	| 'ProjectAdministration'
	| 'RecurringOrder'
	| 'RetailAdministration'
	| 'ReturnOrder'
	| 'ReviewOrderDetails'
	| 'SignIn'
	| 'SignInPasswordReset'
	| 'SignInUsernameRetrieve'
	| 'StoreEventCategorySelector'
	| 'StoreEventsManuallyAddProduct'
	| 'SubProgramAdministration'
	| 'UserAdministration'
	| 'UserBulkAdministration'
	| 'UserFileUpload';

export function createValidationProblemDetailsSchema<FieldErrorsSchema extends z.ZodTypeAny>(
	additionalDataSchema: FieldErrorsSchema,
) {
	return httpProblemDetailsSchema.extend({
		formErrors: formErrorsSchema.optional(),
		fieldErrors: additionalDataSchema.optional(),
	});
}

export function isModelStateErrorResponse<T = unknown>(
	obj: unknown,
): obj is ModelStateErrorResponse<T> {
	return !!obj && typeof obj === 'object' && 'modelState' in obj;
}

export function isAdditionalDataErrorResponse<T = unknown>(
	obj: unknown,
): obj is AdditionalDataErrorResponse<T> {
	return !!obj && typeof obj === 'object' && 'additionalData' in obj;
}
