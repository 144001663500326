import { keepPreviousData, queryOptions } from '@tanstack/react-query';
import type { OdataQueryBuilder } from 'odata-builder';

import { createDataTableQueryOptionsSchema } from '@apple/ui/data-table';
import { execODataQuery } from '@apple/utils/api';
import type { QueryOptions } from '@apple/lib/tanstack-query';
import type { DataTableQueryOptions } from '@apple/ui/data-table';
import type { PageState } from '@apple/utils/pagination';

import { merchandiserFiltersSchema, merchandiserListingSchema } from '../models/listing';
import type { MerchandiserFilters, MerchandiserListing } from '../models/listing';

const merchandiserQueryOptionsSchema =
	createDataTableQueryOptionsSchema<MerchandiserFilters>(merchandiserFiltersSchema);

export function getMerchandiserDataTableQueryOptions(
	options: DataTableQueryOptions<MerchandiserFilters>,
) {
	const { pagination, filters } = merchandiserQueryOptionsSchema.parse(options);
	return queryOptions({
		queryKey: ['merchandisers-odata', filters, pagination],
		queryFn: async ({ signal }) =>
			await execODataQuery<MerchandiserListing>({
				urlPath: '/odata/management/merchandisers',
				rowSchema: merchandiserListingSchema,
				builderFn: odataBuilder =>
					buildMerchandiserODataQuery(odataBuilder, options.filters),
				pagination,
				signal,
			}),
		placeholderData: keepPreviousData,
		throwOnError: true,
		retry: false,
	}) as QueryOptions<PageState<MerchandiserListing>>;
}

// TODO: Find a better way to map filters and sorting to the odata builder
function buildMerchandiserODataQuery(
	builder: OdataQueryBuilder<MerchandiserListing>,
	filters: MerchandiserFilters,
) {
	builder.orderBy({
		field: 'merchandiserName',
		orderDirection: 'asc',
	});

	if (filters.merchandiserName !== undefined) {
		builder.filter({
			field: 'merchandiserName',
			operator: 'contains',
			value: filters.merchandiserName,
			ignoreCase: true,
		});
	}

	if (filters.appleId !== undefined) {
		builder.filter({
			field: 'appleId',
			operator: 'contains',
			value: filters.appleId,
			ignoreCase: true,
		});
	}

	if (filters.countryName !== undefined) {
		builder.filter({
			field: 'countryName',
			operator: 'contains',
			value: filters.countryName,
			ignoreCase: true,
		});
	}
}
