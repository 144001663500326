import { Button, Group, Text } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { FaTimes } from 'react-icons/fa';

export type SelectedItemCountProps = {
	title: string;
	count: number;
	onClearClick: () => Promise<void>;
};

export function SelectedItemCount(props: SelectedItemCountProps) {
	const clearMutation = useMutation({
		mutationKey: ['cart', 'clear'],
		mutationFn: props.onClearClick,
	});

	return (
		<Group justify='flex-end' wrap='nowrap'>
			<Text size='md'>{props.count}</Text>
			<Text>{props.title}</Text>

			<Button
				variant='light'
				color='blue'
				loading={clearMutation.isPending}
				onClick={() => clearMutation.mutate()}
			>
				<FaTimes />
			</Button>
		</Group>
	);
}
