import { Box, Button, Container, Group, Image, Paper, Stack, Text, Title } from '@mantine/core';

import { loginHeroChannel } from '@apple/assets/images';
import { useTranslation } from '@apple/lib/i18next';
import { Link } from '@apple/ui/link';
import { AppleLogo } from '@apple/ui/shell/components/AppleLogo';
import { getEnv } from '@apple/utils/config/env';

const env = getEnv();

export function LogoutPage() {
	const { t } = useTranslation('auth');

	return (
		<Container size={'xl'} my={40}>
			<Group justify='center'>
				<Image src={loginHeroChannel} />
				<Stack justify='space-between' p={30}>
					<Group justify='center'>
						<Box mt='md'>
							<AppleLogo size={38} />
						</Box>

						<Title ta='center' size='h2' mt='xs' fw='lighter'>
							{env.APPLE_APP_TITLE}
						</Title>
					</Group>
					<Text ta='center' size='sm' fw={700}>
						{t('heading.logoutSuccessful')}
					</Text>
					<Paper p={10} mt='sm' radius='md'>
						<Button fullWidth mt='lg' component={Link} to='/login'>
							{t('buttons.backToLogin')}
						</Button>
					</Paper>
				</Stack>
			</Group>
		</Container>
	);
}
