import { useCallback } from 'react';
import { ActionIcon, Badge, Box } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import type { MantineColor, StyleProp } from '@mantine/core';

import { icons } from '@apple/assets';

import { useActiveAnnouncements } from '../hooks/useActiveAnnouncements';

type NotificationMenuProps = {
	c: StyleProp<MantineColor>;
};

export function NotificationMenu({ c }: NotificationMenuProps) {
	const activeAnnouncements = useActiveAnnouncements();

	const handleClick = useCallback(() => {
		notifications.show({
			title: 'Notifications',
			message: 'Not implemented yet',
		});
	}, []);

	// TODO: Fix the icon color
	return (
		<Box style={{ display: 'flex', alignItems: 'center' }}>
			<ActionIcon
				data-testid='notification-icon'
				variant='transparent'
				onClick={handleClick}
				c={c}
			>
				<icons.Notifications />
			</ActionIcon>
			<Box ml='auto'>
				<Badge data-testid='notification-badge' size='sm' mb='xs' mr='0' circle>
					{!activeAnnouncements.isLoading && !activeAnnouncements.error
						? activeAnnouncements.response.unreadCount
						: 0}
				</Badge>
			</Box>
		</Box>
	);
}
