import { Anchor, Group } from '@mantine/core';
import type { PropsWithChildren } from 'react';

import { useTranslation } from '@apple/lib/i18next';
import { Link } from '@apple/ui/link';

export function AppleFooter({ children }: PropsWithChildren) {
	return (
		<Group justify='space-between' w='100%'>
			<Group>
				<CopyrightLink />
			</Group>
			<Group>{children}</Group>
			<Group>
				<PrivacyLinks />
			</Group>
		</Group>
	);
}

const year = new Date().getFullYear();

function CopyrightLink() {
	const { t } = useTranslation('common');
	return (
		<Anchor href='https://arvato.com' c='dimmed' size='sm'>
			{t('shell.copyright', { year })}
		</Anchor>
	);
}

function PrivacyLinks() {
	const { t } = useTranslation('common');

	return (
		<>
			<Link label={t('shell.privacyStatement')} to='/privacy' c='dimmed' size='sm' />
		</>
	);
}
