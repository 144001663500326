import { Box, Group, Stack, Text, UnstyledButton } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';

import { useTranslation } from '@apple/lib/i18next';
import { CardTitle } from '@apple/ui/card';
import { LoadingOverlay } from '@apple/ui/loading-overlay';
import type { LocationListing } from '@apple/features/location';
import type { CardChangeFn, CardFavoriteProps } from '@apple/ui/card';

import { LocationAddress } from './LocationAddress';
import classes from './LocationCard.module.css';
import { LocationProgramList } from './LocationProgramList';

export type LocationCardProps = {
	location: LocationListing;
	selected: boolean;
	onSelectionChange: CardChangeFn<LocationListing>;
	favoriteProps?: Omit<CardFavoriteProps<LocationListing>, 'tooltip'>;
};

export function LocationCard({
	location,
	selected,
	onSelectionChange,
	favoriteProps,
	...others
}: LocationCardProps & Omit<React.ComponentPropsWithoutRef<'button'>, keyof LocationCardProps>) {
	const { t } = useTranslation('shop');

	const toggleSelectionMutation = useMutation({
		mutationKey: ['cart', 'location-selection', 'toggle'],
		mutationFn: () => onSelectionChange(!selected, location),
	});

	const CardLabel = ({ label, value }: { label: string; value: string }) => (
		<Stack gap='0'>
			<Text span size='sm'>
				{label}
			</Text>
			<Text span size='sm' fw='bolder'>
				{value}
			</Text>
		</Stack>
	);

	return (
		<Box pos='relative' display='block'>
			<LoadingOverlay
				overlayProps={{ radius: 'sm' }}
				visible={toggleSelectionMutation.isPending}
				debounce={100}
			/>
			<UnstyledButton
				{...others}
				h='100%'
				w='100%'
				onClick={() => toggleSelectionMutation.mutate()}
				data-checked={selected}
				className={classes.button}
				p='sm'
			>
				<Stack>
					<CardTitle
						value={location}
						title={location.locationName}
						isChecked={selected}
						favoriteProps={
							favoriteProps && {
								tooltip: t('favorite.label'),
								...favoriteProps,
							}
						}
					/>
					<Stack>
						<Group justify='space-between' align='flex-start'>
							<CardLabel label={t('common:label.appleId')} value={location.appleId} />
							<LocationAddress location={location} />
						</Group>
						<LocationProgramList programs={location.programs} />
					</Stack>
				</Stack>
			</UnstyledButton>
		</Box>
	);
}
