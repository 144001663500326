import { z } from 'zod';

import { createExcelErrorSchema, createExcelResultSchema } from '@apple/utils/files';
import {
	countryCodeSchema,
	dateTimeSchema,
	emptyOrNullableLocalDateTimeSchema,
} from '@apple/utils/globalization';
import type { ExcelError, ExcelResult } from '@apple/utils/files';
import type { CountryCode } from '@apple/utils/globalization';

export type UserAssociationType = 'all' | 'country' | 'program' | 'plant' | 'subprogram';

export interface User {
	[key: string]: unknown;
	id: number;
	username: string;
	firstName: string;
	lastName: string;
	email: string;
	activeDate: '' | Date | null;
	inactiveDate: '' | Date | null;
	lastSignIn?: Date | null;
	deleted?: boolean;
	numberFormatCountry: CountryCode | null;
	culture: string;
	currencyIsoCode: string | null;
	orderValueLimit: number | null;
	isLocked: boolean;

	userRoles: UserRole[];
	allAssociation: UserAllAssociation | null;
	plantAssociations: UserPlantAssociation[];
	countryAssociations: UserCountryAssociation[];
	programAssociations: UserProgramAssociation[];
	customerAssociations: UserCustomerAssociation[];
	subProgramAssociations: UserSubProgramAssociation[];

	locationAssociations: UserLocationAssociation[];
}

interface UserAssociation {
	userId: number;
	updatedDate?: Date;
	isDeleted?: boolean;
}

export interface UserAllAssociation extends UserAssociation {}

export interface UserPlantAssociation extends UserAssociation {
	plantId: number;
}

export interface UserCountryAssociation extends UserAssociation {
	countryCode: string;
}

export interface UserProgramAssociation extends UserAssociation {
	customerGroupId: number;
}

export interface UserSubProgramAssociation extends UserProgramAssociation {
	countryCode: string;
}

export interface UserCustomerAssociation extends UserAssociation {
	customerId: number;
}

export interface UserLocationAssociation extends UserAssociation {
	locationId: string;
}

export interface UserRole {
	userId: number;
	roleId: number;
	updatedDate?: Date;
}

export const userAssociationSchema = z.object({
	userId: z.number(),
	updatedDate: dateTimeSchema.optional(),
	isDeleted: z.boolean().catch(false),
});

export const userAllAssociationSchema = userAssociationSchema as z.ZodType<UserAllAssociation>;

export const userPlantAssociationSchema = z.intersection(
	userAssociationSchema,
	z.object({
		plantId: z.number(),
	}),
) as z.ZodType<UserPlantAssociation>;

export const userCountryAssociationSchema = z.intersection(
	userAssociationSchema,
	z.object({
		countryCode: countryCodeSchema,
	}),
) as z.ZodType<UserCountryAssociation>;

export const userProgramAssociationSchema = z.intersection(
	userAssociationSchema,
	z.object({
		customerGroupId: z.number(),
	}),
) as z.ZodType<UserProgramAssociation>;

export const userSubProgramAssociationSchema = z.intersection(
	userProgramAssociationSchema,
	z.object({
		countryCode: z.string(),
	}),
) as z.ZodType<UserSubProgramAssociation>;

export const userCustomerAssociationSchema = z.intersection(
	userAssociationSchema,
	z.object({
		customerId: z.number(),
	}),
) as z.ZodType<UserCustomerAssociation>;

export const userLocationAssociationSchema = z.intersection(
	userAssociationSchema,
	z.object({
		locationId: z.string(),
	}),
) as z.ZodType<UserLocationAssociation>;

export const userRoleSchema = z.object({
	userId: z.number(),
	roleId: z.number(),
	updatedDate: dateTimeSchema.optional(),
}) as z.ZodType<UserRole>;

export const userSchema = z.object({
	id: z.number(),
	username: z.string(),
	firstName: z.string(),
	lastName: z.string(),
	email: z.string().email(),
	activeDate: emptyOrNullableLocalDateTimeSchema,
	inactiveDate: emptyOrNullableLocalDateTimeSchema,
	lastSignIn: dateTimeSchema.nullable().optional(),
	deleted: z.boolean().optional(),
	numberFormatCountry: z.string().nullable(),
	culture: z.string(),
	currencyIsoCode: z.string().nullable(),
	orderValueLimit: z.number().nullable().catch(null),
	isLocked: z.boolean().optional(),
	userRoles: z.array(userRoleSchema),
	allAssociation: userAllAssociationSchema.nullable(),
	plantAssociations: z.array(userPlantAssociationSchema),
	countryAssociations: z.array(userCountryAssociationSchema),
	programAssociations: z.array(userProgramAssociationSchema),
	customerAssociations: z.array(userCustomerAssociationSchema),
	subProgramAssociations: z.array(userSubProgramAssociationSchema),
	locationAssociations: z.array(userLocationAssociationSchema),
}) as z.ZodType<User>;

export type UserExcelResult = ExcelResult<User, 'username', UserExcelResultError>;
export type UserExcelResultError = ExcelError<User, 'username'>;

const excelUserKeySchema = z.object({ username: z.string() });

export const userExcelErrorSchema = createExcelErrorSchema(
	excelUserKeySchema,
) satisfies z.ZodType<UserExcelResultError>;

export const userExcelResultSchema = createExcelResultSchema(
	userSchema,
	userExcelErrorSchema,
	excelUserKeySchema.shape.username,
) satisfies z.ZodType<UserExcelResult>;
