import { memo } from 'react';
import { Group, Stack } from '@mantine/core';
import { deepEqual } from '@tanstack/react-router';

import { useTranslation } from '@apple/lib/i18next';
import { SelectedItemCount } from '@apple/ui/data-table/controls/SelectedItemCount';
import type { LocationProgramListing } from '@apple/features/program/models';
import type { Country } from '@apple/utils/globalization';

import { LocationFilterBar } from './LocationFilterBar';
import { LocationList } from './LocationList';
import type { LocationFilters } from './LocationFilterBar';
import type { LocationListProps } from './LocationList';

export interface FilterableLocationListProps extends LocationListProps {
	options: {
		countries: Country[]; // Needed for the filter bar
		programs: LocationProgramListing[];
	};
	onFiltersChanged: (filters: LocationFilters) => void;
	onClearSelection: () => Promise<void>;
}

export const FilterableLocationList = memo(FilterableLocationListImpl, deepEqual);

export function FilterableLocationListImpl(props: FilterableLocationListProps) {
	const { t } = useTranslation('shop');
	return (
		<Stack>
			<Group
				justify='space-between'
				grow
				styles={{
					root: { alignItems: 'flex-start' },
				}}
			>
				<LocationFilterBar
					options={props.options}
					filters={props.locations.filters}
					onFiltersChanged={props.onFiltersChanged}
					showSelectedFilter
				/>
				<SelectedItemCount
					title={t('steps.locations.locationSelected')}
					count={props.selections.length}
					onClearClick={props.onClearSelection}
				/>
			</Group>
			<LocationList {...props} />
		</Stack>
	);
}
