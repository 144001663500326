import { Carousel } from '@mantine/carousel';
import { Image } from '@mantine/core';

import type { ShopProductModel } from '@apple/features/product/models/product.models';

import classes from './ProductImageCarousel.module.css';

export type ProductImageCarouselProps = {
	product: ShopProductModel;
	variant: 'small' | 'large';
};

export function ProductImageCarousel({ product, variant }: ProductImageCarouselProps) {
	return (
		<>
			{variant === 'small' ? (
				<Carousel
					classNames={classes}
					align='center'
					slideGap='xs'
					slideSize={{ base: '100%' }}
					onClick={e => e.stopPropagation()}
					draggable={false}
					flex={1}
					height={190}
				>
					{product.images.map((image, i) => (
						<Carousel.Slide key={i} data-testid={`${product.itemId}-${i}`}>
							<Image fit='scale-down' radius='sm' src={image.smallImageUrl} />
						</Carousel.Slide>
					))}
				</Carousel>
			) : (
				<Carousel
					classNames={classes}
					align='center'
					slideGap='xs'
					slideSize={{ base: '100%' }}
					onClick={e => e.stopPropagation()}
					draggable={false}
					style={{ display: 'flex', justifyContent: 'center' }}
				>
					{product.images.map((image, i) => (
						<Carousel.Slide
							key={i}
							style={{ display: 'flex', alignItems: 'center' }}
							data-testid={`${product.itemId}-${i}`}
						>
							<Image
								radius='sm'
								src={image.largeImageUrl}
								style={{ margin: 'auto' }}
							></Image>{' '}
						</Carousel.Slide>
					))}
				</Carousel>
			)}
		</>
	);
}
