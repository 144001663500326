import { z } from 'zod';

export interface AnnouncementList {
	announcements: Announcement[];
	unreadCount: number;
	totalCount: number;
}

export interface Announcement {
	contentId: number;
	title: string;
	content: string;
	createdDate: string;
	acknowledged: boolean;
}

export const announcementSchema = z.object({
	contentId: z.number(),
	title: z.string(),
	content: z.string(),
	createdDate: z.string(),
	acknowledged: z.boolean(),
}) satisfies z.ZodType<Announcement>;

export const announcementListSchema = z.object({
	announcements: z.array(announcementSchema),
	unreadCount: z.number(),
	totalCount: z.number(),
}) satisfies z.ZodType<AnnouncementList>;
