import { cloneElement } from 'react';
import { Button, Flex, Text } from '@mantine/core';
import { createLink } from '@tanstack/react-router';
import type { ToOptions } from '@tanstack/react-router';

import classes from './button-with-icon.module.css';

type CustomButtonIconProps = ToOptions & {
	icon: React.ReactNode;
	text: string;
	backgroundColor?: string;
	datatestId?: string;
};

const CustomIconButton: React.FC<CustomButtonIconProps> = ({
	icon,
	text,
	backgroundColor,
	datatestId,
	...toOptions
}) => {
	return (
		<LinkButton
			data-testId={datatestId}
			className={classes.button}
			bg={backgroundColor}
			component='button'
			{...toOptions}
		>
			<Flex className={classes.icon}>{icon && cloneElement(icon as React.ReactElement)}</Flex>
			<Text className={classes.text}>{text}</Text>
		</LinkButton>
	);
};

const LinkButton = createLink(Button);

export default CustomIconButton;
