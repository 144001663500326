import { ActionIcon, Tooltip } from '@mantine/core';
import { showNotification } from '@mantine/notifications';

import { toolbarCommonProps } from './toolbar-button-props';

export function ToolbarButton({
	tooltip,
	icon,
	onClick,
	...rest
}: {
	tooltip: string;
	icon: React.ReactNode;
	disabled?: boolean;
	loading?: boolean;
	'data-testid'?: string;
	onClick?: () => void;
}) {
	return (
		<Tooltip label={tooltip}>
			<ActionIcon
				{...toolbarCommonProps}
				{...rest}
				onClick={() => {
					if (onClick) {
						onClick();
						return;
					}

					showNotification({
						title: 'Not Implemented',
						message: `'${tooltip}' has not been implemented yet`,
						withBorder: true,
					});
				}}
			>
				{icon}
			</ActionIcon>
		</Tooltip>
	);
}
