import { createContext } from 'react';
import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import log from 'loglevel';
import type { AnyRouter, RegisteredRouter } from '@tanstack/react-router';
import type { PropsWithChildren } from 'react';

import { healthQueryOptions } from '@apple/features/health/queries';
import { LoadingOverlay } from '@apple/ui/loading-overlay';

import * as AuthService from '../api/authentication';
import { profileQueryOptions } from '../queries/profile';
import { xsrfQueryOptions } from '../queries/xsrf';
import * as AuthUtils from '../utils/requirements';
import type { RoleName } from '../models/roles';
import type {
	ChangePasswordRequest,
	Permission,
	ProfileDto,
	UpdateProfileRequest,
} from '../models/user';
import type { AuthRequirements } from '../utils/requirements';

export type AuthContext = {
	error: boolean;
	authenticated: boolean;
	profile: ProfileDto | null;
	isAdmin: boolean;
	canAccessMultipleLocations: boolean;
	login: (
		username: string,
		password: string,
		rememberMe: boolean,
		returnUrl: string,
	) => Promise<void>;
	logout: () => Promise<void>;
	reset: () => Promise<void>;
	resetPassword: (username: string) => Promise<void>;
	changePassword: (request: ChangePasswordRequest) => Promise<void>;
	sendForgotUsernameEmail: (email: string) => Promise<void>;
	updateProfile: (request: UpdateProfileRequest) => Promise<void>;
	hasRoles: (roles: RoleName[]) => boolean;
	hasPermissions: (permissions: Permission[]) => boolean;
	filterByPermissions: <T>(items: AuthRequirements<T>[]) => T[];
};

export const AuthContext = createContext<AuthContext | undefined>(undefined);

export function AuthProvider<TRouter extends AnyRouter = RegisteredRouter>({
	children,
	router,
}: PropsWithChildren<{ router: TRouter }>) {
	const client = useQueryClient();
	const [token, health] = useQueries({
		queries: [xsrfQueryOptions(), healthQueryOptions()],
	});
	const profileQuery = useQuery(
		profileQueryOptions(!token.isLoading && !token.isError && !health.isError),
	);
	const loading = token.isLoading || health.isLoading || profileQuery.isLoading;

	async function login(
		username: string,
		password: string,
		rememberMe: boolean,
		returnUrl: string,
	) {
		log.debug('Logging in', { username, returnUrl });
		const response = await AuthService.login({
			userName: username,
			password,
			rememberMe,
			returnUrl,
			termsAndConditionsContentId: 0,
		});

		if (!response.result) {
			log.error('Login failed:', { response });
			return;
		}

		// Retrieve xsrf token for new user
		await token.refetch();
		const profileResponse = await profileQuery.refetch();

		if (profileResponse.isError) {
			log.error('Loading profile failed:', { profileResponse });
			return;
		}

		log.debug('Login successful. Invalidating query caches and fetching profile');
		await client.invalidateQueries();

		// Update the profile query since we invalidated the caches
		client.setQueryData(profileQueryOptions().queryKey, profileResponse.data, {
			updatedAt: Date.now(),
		});

		// Invalidate the router cache after setting the profile data to ensure that the new data is used
		await router.invalidate();

		// Calling `router.navigate` seems to be working fine, however the
		// docs say that `router.history.push` is better suited. Might need
		// to revisit this in the future.
		// https://tanstack.com/router/latest/docs/framework/react/guide/authenticated-routes#redirecting
		await router.navigate({
			to: returnUrl,
		});
	}

	async function logout() {
		log.debug('Logging out');
		await AuthService.logout();
		// Get xsrf token for anonymous user
		await token.refetch();
		await client.invalidateQueries(undefined);
	}

	async function reset() {
		log.debug('Resetting profile query.');
		await client.resetQueries({ queryKey: profileQueryOptions(true).queryKey });
	}

	async function updateProfile(request: UpdateProfileRequest) {
		const updatedProfile = await AuthService.updateProfile(request);
		client.setQueryData(profileQueryOptions().queryKey, updatedProfile);
	}

	async function changePassword(request: ChangePasswordRequest) {
		await AuthService.changePassword(request);
	}

	function filterByPermissions<T>(items: AuthRequirements<T>[]) {
		return AuthUtils.filterByPermissions<T>(profileQuery.data, items);
	}

	function hasRoles(roles: RoleName[]) {
		return AuthUtils.hasRoles(profileQuery.data, roles);
	}

	function hasPermissions(permissions: Permission[]) {
		return AuthUtils.hasPermissions(profileQuery.data, permissions);
	}

	return (
		<AuthContext.Provider
			value={{
				error: health.isError || token.isError,
				authenticated: !!profileQuery.data,
				profile: profileQuery.data ?? null,
				canAccessMultipleLocations: (profileQuery.data?.locationCount ?? 0) > 1,
				isAdmin: AuthUtils.isAdmin(profileQuery.data),
				login,
				logout,
				reset,
				resetPassword: AuthService.resetPassword,
				sendForgotUsernameEmail: AuthService.sendForgotUsernameEmail,
				updateProfile,
				changePassword: changePassword,
				hasRoles,
				hasPermissions,
				filterByPermissions,
			}}
		>
			<LoadingOverlay visible={loading} />
			{!loading && children}
		</AuthContext.Provider>
	);
}
