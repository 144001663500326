import { z } from 'zod';

import { dateTimeSchema } from '@apple/utils/globalization';

export interface ProductFilters
	extends Partial<Omit<ProductListing, 'quantityLimit' | 'availableQuantity'>> {
	partNumber?: string | null;
	name?: string | null;
	quantityLimit?: [number, number] | null; // Allow range filtering
	hasQuantityLimit?: 'yes' | 'no';
	availableQuantity?: [number, number] | null; // Allow range filtering
	availability?: 'inStock' | 'outOfStock';
}
export interface ProductListing {
	isEnabledInErp: boolean;
	isEndOfLifeComplete: boolean;
	isActive: boolean;
	itemId: string;
	sku: string;
	unitOfMeasure: string;
	requiresApproval: boolean;
	isBackorderable: boolean;
	warehouseCode: string;
	currencyCode: string;
	productName: string;
	availableQuantity: number;
	customerCount: number;
	commercialPrice: number;
	visibility?: string;
	isNewProductIntroductionComplete: boolean;
	hasImages: boolean;
	quantityLimit?: number | null;
	priceOverride?: number | null;
	activeDate?: Date | string | null;
	inactiveDate?: Date | string | null;
	commercialPriceUSD?: number | null;
}

export const productListingSchema = z.object({
	itemId: z.string(),
	sku: z.string(),
	productName: z.string(),
	warehouseCode: z.string(),
	availableQuantity: z.number(),
	customerCount: z.number(),
	unitOfMeasure: z.string(),
	quantityLimit: z.onumber().nullable(),
	commercialPrice: z.number(),
	currencyCode: z.string(),
	commercialPriceUSD: z.onumber().nullable(),
	priceOverride: z.onumber().nullable(),
	visibility: z.ostring(),
	isEnabledInErp: z.boolean(),
	requiresApproval: z.boolean(),
	isBackorderable: z.boolean(),
	isNewProductIntroductionComplete: z.boolean(),
	isEndOfLifeComplete: z.boolean(),
	isActive: z.boolean(),
	activeDate: dateTimeSchema.nullable(),
	inactiveDate: dateTimeSchema.nullable(),
	hasImages: z.boolean(),
}) satisfies z.ZodType<ProductListing>;

export const productFiltersSchema = productListingSchema
	.partial()
	.extend({
		partNumber: z.ostring().nullable(),
		name: z.ostring().nullable(),
		quantityLimit: z.tuple([z.number(), z.number()]).optional().nullable(),
		hasQuantityLimit: z.enum(['yes', 'no']).optional(),
		availableQuantity: z.tuple([z.number(), z.number()]).optional().nullable(),
		availability: z.enum(['inStock', 'outOfStock']).optional(),
	})
	.catch({}) as z.ZodType<ProductFilters>;

export interface ProductAuditListing {
	dateTime: Date;
	username: string;
	event: string;
	itemId: string | null;
}

export interface ProductAuditFilters extends Partial<ProductAuditListing> {}

export const productAuditListingSchema = z.object({
	dateTime: z.date(),
	username: z.string(),
	event: z.string(),
	itemId: z.string(),
}) satisfies z.ZodType<ProductAuditListing>;

export const productAuditFiltersSchema =
	productAuditListingSchema.partial() satisfies z.ZodType<ProductAuditFilters>;
