import React, { memo } from 'react';
import { Text } from '@mantine/core';
import moment from 'moment';

import { useTranslation } from '@apple/lib/i18next';

interface StockIndicatorProps {
	itemState: string;
	isInStock: boolean;
	estimatedInStockDate: string | null;
}

export const StockIndicator: React.FC<StockIndicatorProps> = memo(
	({ itemState, isInStock, estimatedInStockDate }) => {
		const { t } = useTranslation('shop');

		const isDateToBeDetermined =
			!estimatedInStockDate ||
			estimatedInStockDate === '' ||
			moment().add(1, 'year').isBefore(moment(estimatedInStockDate, 'L'));

		let stockText = itemState;
		if (!isInStock) {
			stockText = stockText.concat(
				' - ',
				isDateToBeDetermined
					? t('steps.products.labels.stockStatus.toBeDetermined')
					: (estimatedInStockDate ?? ''),
			);
		}

		return (
			<Text data-testid='inStockText' c={!isInStock ? 'red' : 'green'}>
				{stockText}
			</Text>
		);
	},
);
