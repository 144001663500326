import { useEffect } from 'react';
import { Group, Stack, Title } from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';

import { FormattedDate } from '@apple/utils/globalization';
import type { Announcement } from '@apple/features/announcements';

import { acknowledgeContent } from '../api';
import styles from './AnnouncementItem.module.css';

type AnnouncementItemProps = {
	announcement: Announcement;
	markReadOnView?: boolean;
};

export function AnnouncementItem({ announcement, markReadOnView }: AnnouncementItemProps) {
	const editor = useEditor({
		extensions: [StarterKit],
		content: announcement.content,
		editable: false,
	});

	useEffect(() => {
		if (editor && editor.getHTML() !== announcement.content) {
			editor.commands.setContent(announcement.content);
		}

		if (!announcement.acknowledged && markReadOnView) {
			void acknowledgeContent(announcement.contentId);
		}
	}, [
		announcement.acknowledged,
		announcement.content,
		announcement.contentId,
		editor,
		markReadOnView,
	]);

	return (
		<Stack>
			<Group justify='flex-end'>
				<FormattedDate value={announcement.createdDate} />
			</Group>
			<Title order={4}>{announcement.title}</Title>
			{editor && (
				<RichTextEditor editor={editor} className={styles.textEditor}>
					<RichTextEditor.Content />
				</RichTextEditor>
			)}
		</Stack>
	);
}
