import { useMemo } from 'react';
import { AppShell, Box, Container, rem, ScrollArea, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Outlet } from '@tanstack/react-router';
import type { AppShellProps } from '@mantine/core';
import type { PropsWithChildren } from 'react';

import { ActiveAnnouncements, useActiveAnnouncements } from '@apple/features/announcements';
import { AppleFooter } from '@apple/ui/layouts/components/apple-footer';
import { AppleHeader } from '@apple/ui/layouts/components/apple-header';
import { SidebarMenu } from '@apple/ui/layouts/components/sidebar-menu';
import { TopbarUserMenu } from '@apple/ui/layouts/components/topbar-user-menu';
import { useColorScheme } from '@apple/ui/shell';
import type { MenuItem, MenuItemGroup } from '@apple/ui/layouts/components/menu-item';

import classes from './AppleLayout.module.css';

const sidebarHorizontalPadding = 'xs';
const sidebarCollapseBreakpoint = 'sm';

export function AppleLayout({
	children,
	fluid = true,
	globalMenuItems = [],
	sidebarMenuItems = [],
	hideFooter = false,
}: PropsWithChildren<{
	fluid?: boolean;
	hideFooter?: boolean;
	globalMenuItems: MenuItem[];
	sidebarMenuItems?: MenuItemGroup[];
}>) {
	const hasAdditionalMenuItems = sidebarMenuItems.length > 0;
	const activeAnnouncements = useActiveAnnouncements();
	const { isDark } = useColorScheme();
	const theme = useMantineTheme();

	const [mobileOpened, { toggle: toggleMobile }] = useDisclosure(false);

	const appshellProps = useMemo<AppShellProps>(
		() => ({
			withBorder: false,
			padding: 0,
			header: { height: theme.other.headerHeight },
			footer: { height: theme.other.footerHeight },
			classNames: {
				root: classes.root,
				main: classes.main,
				footer: classes.footer,
			},
			navbar: {
				width: theme.other.primarySidebar.width,
				breakpoint: sidebarCollapseBreakpoint,
				collapsed: {
					mobile: !mobileOpened,
					desktop: !hasAdditionalMenuItems,
				},
			},
			miw: rem(theme.other.page.minWidth),
		}),
		[
			theme.other.headerHeight,
			theme.other.footerHeight,
			theme.other.primarySidebar.width,
			theme.other.page.minWidth,
			mobileOpened,
			hasAdditionalMenuItems,
		],
	);

	// useLogger('AppleLayout', [{ appshellProps }]);

	return (
		<AppShell {...appshellProps}>
			<AppShell.Header className={classes.headerColors}>
				<Container size={fluid ? undefined : 'lg'} fluid={fluid}>
					<AppleHeader
						globalMenuItems={globalMenuItems}
						sidebarOpen={mobileOpened}
						toggleSidebar={toggleMobile}
						sidebarCollapseBreakpoint={sidebarCollapseBreakpoint}
					/>
					{!activeAnnouncements.isLoading && !activeAnnouncements.error && (
						<ActiveAnnouncements announcementList={activeAnnouncements.response} />
					)}
				</Container>
			</AppShell.Header>

			<AppShell.Navbar
				h='100%'
				hiddenFrom={!hasAdditionalMenuItems ? sidebarCollapseBreakpoint : undefined}
				bg='light-dark(var(--mantine-color-gray-0), var(--mantine-color-gray-10))'
				c={isDark ? theme.white : theme.black}
				py='md'
				// the primary sidebar is z100 so this one should be a higher number (aka lower precedence / aka goes behind the primary sidebar)
				zIndex={102}
			>
				<ScrollArea flex={1} pl={sidebarHorizontalPadding} pr='lg'>
					<TopbarUserMenu
						variant={isDark ? 'light' : 'dark'}
						hiddenFrom={sidebarCollapseBreakpoint}
						justify='end'
					/>

					<SidebarMenu
						size='sm'
						sidebarCollapseBreakpoint={sidebarCollapseBreakpoint}
						globalMenuItems={globalMenuItems}
						sidebarMenuItems={sidebarMenuItems}
					/>
				</ScrollArea>
			</AppShell.Navbar>

			<AppShell.Main
				py={0}
				mt='calc(var(--app-shell-header-height))'
				pl='calc(var(--app-shell-navbar-offset, 0rem) + var(--app-shell-aside-offset, 0rem) + var(--app-shell-padding))'
				pr='calc(var(--app-shell-padding))'
				// h='100%'
				// mih={
				// 	hideFooter
				// 		? 'calc(100dvh - var(--app-shell-header-height))'
				// 		: 'calc(100dvh - var(--app-shell-header-height) - var(--app-shell-footer-height))'
				// }
			>
				{children ?? <Outlet />}
			</AppShell.Main>

			<AppShell.Footer
				hidden={hideFooter}
				styles={{
					footer: {
						marginLeft:
							sidebarMenuItems.length <= 0
								? undefined
								: 'calc(var(--app-shell-navbar-width))',
						// position: 'relative',
						// bottom: 'unset',
					},
				}}
			>
				<Box className={classes.footerRoot}>
					<Container
						className={classes.footerContainer}
						fluid={fluid}
						size={fluid ? undefined : 'lg'}
					>
						<AppleFooter />
					</Container>
				</Box>
			</AppShell.Footer>
		</AppShell>
	);
}
