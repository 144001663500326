import { Center, Stack, Text, Title } from '@mantine/core';
import { useInterval } from '@mantine/hooks';
import { useQueries, useQueryClient } from '@tanstack/react-query';

import { xsrfQueryOptions } from '@apple/features/auth';
import { healthQueryOptions } from '@apple/features/health/queries';
import { useTranslation } from '@apple/lib/i18next';
import { getEnv } from '@apple/utils/config/env';

import { AppleLogo } from '../components/AppleLogo';

const { APPLE_APP_TITLE } = getEnv();

export function MaintenancePage() {
	const { t } = useTranslation('common');
	const [token, health] = useQueries({
		queries: [xsrfQueryOptions(true), healthQueryOptions(true)],
	});
	const client = useQueryClient();

	useInterval(
		() => {
			if (token.isSuccess && health.isSuccess) {
				void client.resetQueries({ queryKey: xsrfQueryOptions().queryKey, exact: true });
				void client.resetQueries({ queryKey: healthQueryOptions().queryKey, exact: true });
			}
		},
		30 * 1000,
		{ autoInvoke: true },
	);

	return (
		<Center pt={28}>
			<Stack align='center'>
				<AppleLogo size={38} />
				<Title>{t('maintenance.heading')}</Title>
				<Text>{t('maintenance.message', { siteName: APPLE_APP_TITLE })}</Text>
			</Stack>
		</Center>
	);
}
