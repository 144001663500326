import { useCallback, useEffect, useState } from 'react';
import { Combobox, Group, Stack, Text, TextInput, useCombobox } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';

import { icons } from '@apple/assets';
import { useTranslation } from '@apple/lib/i18next';
import { FilterBarDropdown } from '@apple/ui/data-table/controls/FilterBarDropdown';
import type { LocationProgramListing, ProgramId } from '@apple/features/program/models';
import type { Country, CountryCode } from '@apple/utils/globalization';

export type LocationFilters = {
	keywords?: string;
	country?: CountryCode;
	program?: ProgramId;
};

export type LocationFilterBarProps = {
	options: {
		countries: Country[];
		programs: LocationProgramListing[];
	};
	filters: LocationFilters;
	debounceTime?: number;
	showSelectedFilter?: boolean;
	onFiltersChanged: (filters: LocationFilters) => void;
};

export function LocationFilterBar({
	options,
	filters,
	onFiltersChanged,
	debounceTime = 500,
	showSelectedFilter,
}: LocationFilterBarProps) {
	const { t } = useTranslation('shop');
	const [selectedCountryName, setSelectedCountryName] = useState<string | undefined>('');
	const [selectedProgramName, setSelectedProgramName] = useState<string | undefined>('');
	const [keywords, setKeywords] = useDebouncedState(filters?.keywords, debounceTime);

	const countrySelector = useCombobox({
		onDropdownClose: () => countrySelector.resetSelectedOption(),
	});
	const programSelector = useCombobox({
		onDropdownClose: () => programSelector.resetSelectedOption(),
	});

	useEffect(() => {
		// Only call onFiltersChanged if keywords have actually changed
		if (filters.keywords !== keywords) {
			onFiltersChanged({ ...filters, keywords });
		}
	}, [onFiltersChanged, filters, keywords]);

	const countryOptions = [
		<Combobox.Option value='' key='all-countries'>
			{t('steps.locations.filterBar.countryPlaceholder')}
		</Combobox.Option>,
		...options.countries.map(country => (
			<Combobox.Option value={country.code} key={country.code}>
				{country.name}
			</Combobox.Option>
		)),
	];

	const programOptions = [
		<Combobox.Option value='' key='all-programs'>
			{t('steps.locations.filterBar.programPlaceholder')}
		</Combobox.Option>,
		...options.programs.map(program => (
			<Combobox.Option value={program.id.toString()} key={program.id}>
				{program.name}
			</Combobox.Option>
		)),
	];

	const handleCountrySelected = useCallback(
		(countryCode: CountryCode) => {
			if (filters.country !== countryCode) {
				const selectedCountry = options.countries.find(
					country => country.code === countryCode,
				);
				setSelectedCountryName(selectedCountry?.name);
				onFiltersChanged({ ...filters, country: countryCode });
			}
			countrySelector.closeDropdown();
		},
		[onFiltersChanged, filters, countrySelector, options.countries],
	);

	const handleProgramSelected = useCallback(
		(programId: string) => {
			const parsedProgramId: ProgramId = parseInt(programId);
			if (filters.program !== parsedProgramId) {
				const selectedProgram = options.programs.find(
					program => program.id.toString() === programId,
				);
				setSelectedProgramName(selectedProgram?.name);
				onFiltersChanged({ ...filters, program: parsedProgramId });
			}
			programSelector.closeDropdown();
		},
		[onFiltersChanged, filters, programSelector, options.programs],
	);

	function handleKeywordsChanged(value: string) {
		setKeywords(value === '' ? undefined : value);
	}

	return (
		<Stack>
			<Group gap='xs'>
				<FilterBarDropdown
					options={countryOptions}
					store={countrySelector}
					onOptionSubmit={x => handleCountrySelected(x as CountryCode)}
					targetIcon={<icons.Globe />}
					testId='country-selector'
				/>
				<FilterBarDropdown
					options={programOptions}
					store={programSelector}
					onOptionSubmit={handleProgramSelected}
					targetIcon={<icons.Filter />}
					testId='program-selector'
				/>
				<TextInput
					placeholder={t('steps.locations.filterBar.keywordsPlaceholder')}
					leftSection={<icons.Search />}
					defaultValue={keywords}
					onChange={event => handleKeywordsChanged(event.currentTarget.value)}
					data-testid='keywords-filter'
				/>
			</Group>
			{showSelectedFilter && (
				<Text m={10}>
					{`${selectedCountryName || t('steps.locations.filterBar.countryPlaceholder')} - ${selectedProgramName || t('steps.locations.filterBar.programPlaceholder')}`}
				</Text>
			)}
		</Stack>
	);
}
