import { memo } from 'react';
import {
	Box,
	Button,
	Card,
	Center,
	Group,
	Modal,
	Stack,
	Text,
	Title,
	Tooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useMutation } from '@tanstack/react-query';

import { icons } from '@apple/assets';
import { StockIndicator } from '@apple/features/cart/components/stock-indicator';
import { useTranslation } from '@apple/lib/i18next';
import { CardTitle } from '@apple/ui/card';
import { LoadingOverlay } from '@apple/ui/loading-overlay';
import type { ShopProductModel } from '@apple/features/product/models/product.models';

import classes from './ProductCard.module.css';
import { ProductDetail } from './ProductDetail';
import { ProductImageCarousel } from './ProductImageCarousel';

export type ProductCardProps = {
	product: ShopProductModel;
	selected: boolean;
	onChange: (checked: boolean, product: ShopProductModel) => Promise<void>;
	favorite?: boolean;
	onFavoriteChange?: (checked: boolean, product: ShopProductModel) => Promise<void>;
};

export function ProductCard({
	product,
	selected,
	onChange,
	favorite,
	onFavoriteChange,
}: ProductCardProps) {
	const { t } = useTranslation('shop');
	const [opened, { open, close }] = useDisclosure(false);

	const selectionToggle = useMutation({
		mutationKey: ['cart', 'product-card', 'selection', 'toggle'],
		mutationFn: async (updatedValue: boolean) => {
			await onChange(updatedValue, product);
		},
	});

	const favoriteToggle = useMutation({
		mutationKey: ['cart', 'product-card', 'favorite', 'toggle'],
		mutationFn: async (checked?: boolean) => {
			if (favorite === undefined || !onFavoriteChange) {
				return;
			}

			await onFavoriteChange(checked ?? !favorite, product);
		},
		onSuccess: () => {
			// TODO: refresh the query
		},
	});

	return (
		<Box pos='relative'>
			<LoadingOverlay visible={selectionToggle.isPending} debounce={100} />
			<Stack>
				<Card
					component='a'
					className={classes.card}
					data-checked={selected}
					onClick={() => selectionToggle.mutate(!selected)}
				>
					<Stack>
						<CardTitle
							value={product}
							isChecked={selected}
							favoriteProps={{
								tooltip: t('favorite.label'),
								favorite: favorite ?? false,
								favoriting: favoriteToggle.isPending,
								onFavoriteChange: async checked =>
									await favoriteToggle.mutateAsync(checked),
							}}
						/>
						<CardBody product={product} onClickProductLink={open} />
					</Stack>
				</Card>
				<Modal size='auto' opened={opened} onClose={close}>
					<ProductDetail
						product={product}
						selected={selected}
						favorite={favorite}
						onChange={x => selectionToggle.mutate(x)}
						onFavoriteChange={onFavoriteChange}
					/>
				</Modal>
			</Stack>
		</Box>
	);
}

type ImageSectionProps = { product: ShopProductModel };

const ImageSection = memo(({ product }: ImageSectionProps) => (
	<Center>
		<ProductImageCarousel product={product} variant='small' />
	</Center>
));

type CardBodyProps = {
	product: ShopProductModel;
	onClickProductLink: () => void;
};

const CardBody = memo((props: CardBodyProps) => {
	const { product, onClickProductLink } = props;
	return (
		<Stack gap='xs'>
			<ImageSection product={product} />
			<Tooltip label={product.title}>
				<Button
					className={classes.productName}
					onClick={onClickProductLink}
					variant='subtle'
				>
					<Title lineClamp={1} order={4} style={{ whiteSpace: 'normal' }}>
						{product.title}
					</Title>
				</Button>
			</Tooltip>

			<Group>
				<Text span>{product.sku}</Text>
				<Text span>|</Text>
				<Text span>{product.unitOfMeasure}</Text>
			</Group>
			<Group justify='space-between' align='flex-start'>
				<StockIndicator
					itemState={product.itemState}
					isInStock={product.isInStock}
					estimatedInStockDate={product.estimatedInStockDate}
				/>
			</Group>
			<Group align='flex-end'>
				<Text span>{product.priceRange}</Text>
			</Group>
			<CardIcons product={product} />
		</Stack>
	);
});

type CardIconsProps = { product: ShopProductModel };

const CardIcons = memo((props: CardIconsProps) => {
	const { product } = props;
	const { t } = useTranslation('shop');

	return (
		<Group justify='space-between' align='flex-start' mih={32} wrap='nowrap'>
			<Box>
				{product.requiresApproval && (
					<icons.Warning
						title={t('steps.products.labels.requiresApproval')}
						className={classes.approval}
						data-testid='requiresApproval'
					/>
				)}
			</Box>
			<Box>
				{product.isConfidential && (
					<icons.Lock
						title={t('steps.products.labels.confidential')}
						className={classes.confidential}
						data-testid='isConfidential'
					/>
				)}
			</Box>
		</Group>
	);
});
