import { ToggleSidebarIcon } from '@apple/assets/icons';
import { useTranslation } from '@apple/lib/i18next';
import { ToolbarButton } from '@apple/ui/data-table/controls/Toolbar';

export interface SidebarToggleButtonProps {
	collapsed: boolean;
	onClick: () => void;
	labels?: {
		expand: string;
		collapse: string;
	};
}

export function SidebarToggleButton(props: SidebarToggleButtonProps) {
	const { t } = useTranslation('common');
	return (
		<ToolbarButton
			tooltip={
				props.collapsed
					? (props.labels?.expand ?? t('common:shell.sidebar.expand'))
					: (props.labels?.collapse ?? t('common:shell.sidebar.collapse'))
			}
			icon={<ToggleSidebarIcon size='25' />}
			onClick={props.onClick}
		/>
	);
}
