import { useMemo } from 'react';
import {
	Button,
	Code,
	Divider,
	Group,
	Table as MantineTable,
	Pagination,
	Progress,
	rem,
	ScrollArea,
	Select,
	Stack,
	Text,
	useMantineTheme,
} from '@mantine/core';
import { useDebouncedValue, useDisclosure } from '@mantine/hooks';
import { flexRender } from '@tanstack/react-table';
import { isEqual } from 'lodash';
import type { CSSProperties } from '@mantine/core';
import type { RowData, Table } from '@tanstack/react-table';

import { SortAscIcon, SortDescIcon } from '@apple/assets/icons';
import { useTranslation } from '@apple/lib/i18next';
import { Toolbar } from '@apple/ui/data-table/controls/Toolbar';
import { SidebarToggleButton } from '@apple/ui/layouts/components/sidebar-toggle-button';
import type { TableVariant } from '@apple/ui/data-table';
import type { ToolbarProps } from '@apple/ui/data-table/controls/Toolbar';

import { FiltersList } from './filters-list';

// TODO: Move to theme file
const DEFAULT_FILTER_TRANSITION_MS = 200;

// eslint-disable-next-line react-refresh/only-export-components
export const PAGINATION_OPTIONS = [
	{ value: '25', label: '25' },
	{ value: '50', label: '50' },
	{ value: '100', label: '100' },
] as const;

export function DataTable<T extends RowData>({
	table,
	toolbar,
	loading = false,
	hidePageSize = false,
	hidePagination = false,
	minWidth = '768px',
	debug = false,
	variant = 'apple-table',
}: {
	table: Table<T>;
	toolbar?: Omit<ToolbarProps, 'leftSection'>;
	loading?: boolean;
	hidePageSize?: boolean;
	hidePagination?: boolean;
	minWidth?: string;
	debug?: boolean;
	variant?: TableVariant;
}) {
	const { t } = useTranslation('controls');
	const [loadingDebounced] = useDebouncedValue(loading, 300);
	const [filtersCollapsed, filterCollapseActions] = useDisclosure(false);
	const theme = useMantineTheme();

	const showTableFilters = useMemo(
		() => table.getAllColumns().some(column => column.getCanFilter()),
		[table],
	);

	return (
		<Group gap={5} align='flex-start' wrap='nowrap' w='100%' h='100%'>
			{showTableFilters && (
				<TableFilters
					table={table}
					width={!filtersCollapsed ? theme.other.page.minWidth : 0}
					clearLabel={t('dataTable.resetFilters')}
					debug={debug}
				/>
			)}

			<Stack
				align='stretch'
				justify='space-between'
				h='100%'
				w='100%'
				mih='inherit'
				gap={0}
				style={{
					flex: 1,
					transitionProperty: 'flex',
					transitionDuration: DEFAULT_FILTER_TRANSITION_MS.toString() + 'ms',
					direction: table.options.columnResizeDirection,
				}}
			>
				<Stack
					align='stretch'
					justify='flex-start'
					gap={0}
					style={{
						direction: table.options.columnResizeDirection,
					}}
				>
					{!toolbar ? null : (
						<Toolbar
							leftSection={() => (
								<SidebarToggleButton
									collapsed={filtersCollapsed}
									onClick={filterCollapseActions.toggle}
								/>
							)}
							{...toolbar}
						/>
					)}

					<MantineTable.ScrollContainer minWidth={minWidth} maw='100%'>
						<TableBody table={table} variant={variant} />
					</MantineTable.ScrollContainer>
				</Stack>

				{loadingDebounced ? <Progress radius='xl' size='xs' value={100} animated /> : null}

				{(!hidePageSize || !hidePagination) && (
					<TablePagingButtons
						table={table}
						hidePageSize={hidePageSize}
						hidePagination={hidePagination}
						pageSizeLabel={t('dataTable.pageSize')}
					/>
				)}
			</Stack>
		</Group>
	);
}

export function TableBody<TRow>({
	table,
	striped = true,
	variant = undefined,
}: {
	table: Table<TRow>;
	striped?: boolean;
	variant?: TableVariant;
}) {
	return (
		<MantineTable
			striped={striped}
			highlightOnHover
			stickyHeader
			variant={variant}
			// w={table.getTotalSize()}
		>
			<MantineTable.Thead bg='light-dark(white, var(--mantine-color-dark-9))'>
				{table.getHeaderGroups().map(headerGroup => (
					<MantineTable.Tr key={headerGroup.id}>
						{headerGroup.headers
							.filter(x => !x.column.columnDef.meta?.hideColumn)
							.map(header => (
								<MantineTable.Th
									key={header.id}
									colSpan={header.colSpan}
									w={header.getSize()}
									pos='relative'
									onClick={header.column.getToggleSortingHandler()}
									style={{
										textWrap: 'nowrap',
										cursor: header.column.getCanSort() ? 'pointer' : 'auto',
										userSelect: 'none',
									}}
								>
									{header.isPlaceholder
										? null
										: flexRender(
												header.column.columnDef.header,
												header.getContext(),
											)}
									<Group
										pos='absolute'
										top='0'
										right={0}
										h='100%'
										justify='end'
										align='center'
									>
										{{
											asc: <SortAscIcon />,
											desc: <SortDescIcon />,
											false: null,
										}[header.column.getIsSorted() as string] ?? null}
										{header.column.getIsLastColumn() ? null : (
											<Divider
												size='xs'
												orientation='vertical'
												c='white'
												my={6}
												onMouseDown={header.getResizeHandler()} //for desktop
												onTouchStart={header.getResizeHandler()} //for mobile
												onDoubleClick={() => header.column.resetSize()}
												style={{
													cursor: 'col-resize',
													userSelect: 'none',
													touchAction: 'none',
													transform:
														table.options.columnResizeMode ===
															'onEnd' && header.column.getIsResizing()
															? `translateX(${
																	(table.options
																		.columnResizeDirection ===
																	'rtl'
																		? -1
																		: 1) *
																	(table.getState()
																		.columnSizingInfo
																		.deltaOffset ?? 0)
																}px)`
															: '',
												}}
											/>
										)}
									</Group>
								</MantineTable.Th>
							))}
					</MantineTable.Tr>
				))}
			</MantineTable.Thead>
			<MantineTable.Tbody>
				{table.getRowModel().rows.map(row => (
					<MantineTable.Tr key={row.id}>
						{row
							.getVisibleCells()
							.filter(cell => !cell.column.columnDef.meta?.hideColumn)
							.map(cell => (
								<MantineTable.Td key={cell.id} w={cell.column.getSize()}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</MantineTable.Td>
							))}
					</MantineTable.Tr>
				))}
			</MantineTable.Tbody>
		</MantineTable>
	);
}

function TablePagingButtons<TRow>({
	table,
	pageSizeLabel,
	hidePageSize,
	hidePagination,
}: {
	table: Table<TRow>;
	pageSizeLabel: string;
	hidePageSize: boolean;
	hidePagination: boolean;
}) {
	return (
		<Group justify='end' align='center' p='lg'>
			{!hidePageSize && (
				<>
					<Text>{pageSizeLabel}</Text>
					<Select
						data={PAGINATION_OPTIONS}
						w={rem(100)}
						checkIconPosition='right'
						value={table.getState().pagination.pageSize.toString()}
						onChange={value => value && table.setPageSize(Number(value))}
					/>
				</>
			)}
			{!hidePagination && (
				<Pagination
					total={table.getPageCount()}
					value={table.getState().pagination.pageIndex + 1}
					onChange={pageNumber => table.setPageIndex(pageNumber - 1)}
				/>
			)}
		</Group>
	);
}

function TableFilters<TRow>({
	table,
	width,
	clearLabel,
	debug,
}: {
	table: Table<TRow>;
	width: CSSProperties['width'];
	clearLabel: string;
	debug: boolean;
}) {
	const isDirty = !isEqual(table.getState().columnFilters, table.initialState.columnFilters);
	return (
		<ScrollArea
			h='100%'
			bg='light-dark(white, var(--mantine-color-dark-8))'
			scrollbars='y'
			style={{
				zIndex: 1,
				flexShrink: 0,
				flexBasis: width,
				transitionProperty: 'flex-basis',
				transitionDuration: DEFAULT_FILTER_TRANSITION_MS.toString() + 'ms',
			}}
		>
			<Stack p='xs'>
				<FiltersList headers={table.getFlatHeaders()} />
				<Button disabled={!isDirty} onClick={() => table.resetColumnFilters()}>
					{clearLabel}
				</Button>
				{debug && <Code block>{JSON.stringify(table.getState(), null, 2)}</Code>}
			</Stack>
		</ScrollArea>
	);
}
