import { Box, Center, Pagination, rem, ScrollAreaAutosize, SimpleGrid, Title } from '@mantine/core';

import { LocationCard } from '@apple/features/cart/components/LocationCard';
import { useTranslation } from '@apple/lib/i18next';
import { LoadingOverlay } from '@apple/ui/loading-overlay';
import type { PaginatedApi } from '@apple/utils/pagination';

import type { LocationId, LocationListing } from '../models/location';
import type { LocationFilters } from './LocationFilterBar';

// Location list contains the cards and pagination controls as well as deselect all locations control
export interface LocationListProps {
	loading: boolean;
	locations: PaginatedApi<LocationListing, LocationFilters>;
	selections: LocationId[];
	onChange: (args: LocationSelectionChangedArgs) => Promise<void>;
}

export type LocationSelectionChangedArgs = {
	location: LocationListing; // could be of Location depending on implementation
	checked: boolean;
};

export function LocationList({ loading, locations, selections, onChange }: LocationListProps) {
	const { t } = useTranslation('shop');

	const currentPage = locations?.items ?? [];

	if (!loading && currentPage.length === 0) {
		return (
			<Center pt={250}>
				<Title order={3}> {t('steps.locations.noLocationsFound')}</Title>
			</Center>
		);
	}

	return (
		<>
			<ScrollAreaAutosize h={'100%'}>
				<Box pt={'xs'} mb={50} pos='relative'>
					<LoadingOverlay visible={loading} />
					<SimpleGrid cols={{ base: 1, sm: 2, md: 3, lg: 4, xl: 4 }} mih={rem(100)}>
						{currentPage.map(location => (
							<LocationCard
								key={location.id.toString()}
								location={location}
								selected={selections.some(locationId => locationId == location.id)}
								onSelectionChange={async (checked, location) => {
									await onChange({ checked, location });
								}}
							/>
						))}
					</SimpleGrid>
				</Box>
			</ScrollAreaAutosize>
			{locations.hasMultiplePages && (
				<Center mb={rem(50)}>
					<Pagination
						total={locations.pageCount}
						value={locations.pageNumber}
						onChange={locations.gotoPage}
						onFirstPage={locations.gotoFirstPage}
						onLastPage={locations.gotoLastPage}
						onPreviousPage={locations.gotoPreviousPage}
						onNextPage={locations.gotoNextPage}
						withControls
						data-testid='location-pagination'
					/>
				</Center>
			)}
		</>
	);
}
