import { z } from 'zod';

import { dateTimeSchema } from '@apple/utils/globalization/models';
import type { Guid } from '@apple/utils/primitive';

export const MAX_FILE_SIZE = 2048 * 1024;
export const ALLOWED_FILE_TYPES = [
	'.png',
	'.jpg',
	'.jpeg',
	'.bmp',
	'.gif',
	'.ico',
	'.tif',
	'.pdf',
	'.doc',
	'.docx',
	'.xls',
	'.xlsx',
	'.ppt',
	'.pptx',
	'.pps',
	'.ppsx',
	'.txt',
	'.rtf',
	'.md',
	'.zip',
	'.7z',
	'.rar',
	'.xml',
	'.json',
	'.html',
];

export interface UserFileModel {
	id: Guid;
	name: string;
	internalName: string;
	description: string;
	owner: string;
	uploadedOn: Date | string | null;
	size: number;
	url: string;
}

export const userFileModelSchema = z.object({
	id: z.string(),
	name: z.string(),
	internalName: z.string(),
	description: z.string(),
	owner: z.string(),
	uploadedOn: dateTimeSchema,
	size: z.number(),
	url: z.string(),
}) satisfies z.ZodType<UserFileModel>;
