import {
	ActionIcon,
	Badge,
	Button,
	Center,
	Group,
	SimpleGrid,
	Stack,
	Text,
	Title,
	Tooltip,
} from '@mantine/core';
import { Link, RichTextEditor } from '@mantine/tiptap';
import { useMutation } from '@tanstack/react-query';
import { TextAlign } from '@tiptap/extension-text-align';
import { Underline } from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';

import { icons } from '@apple/assets';
import { StockIndicator } from '@apple/features/cart/components/stock-indicator';
import { useTranslation } from '@apple/lib/i18next';
import type { ShopProductModel } from '@apple/features/product/models/product.models';

import classes from './ProductDetail.module.css';
import { ProductImageCarousel } from './ProductImageCarousel';

export type ProductDetailProps = {
	product: ShopProductModel;
	selected: boolean;
	favorite: boolean | undefined;
	onChange: (checked: boolean, product: ShopProductModel) => void;
	onFavoriteChange?: (checked: boolean, product: ShopProductModel) => Promise<void>;
};

export function ProductDetail({
	product,
	selected,
	favorite,
	onChange,
	onFavoriteChange,
}: ProductDetailProps) {
	const { t } = useTranslation('shop');

	const favoriteToggle = useMutation({
		mutationKey: ['cart', 'product-detail', 'favorite', 'toggle'],
		mutationFn: async () => {
			onFavoriteChange && (await onFavoriteChange(!favorite, product));
		},
	});

	const editor = useEditor({
		extensions: [
			StarterKit,
			Underline,
			Link,
			TextAlign.configure({ types: ['heading', 'paragraph'] }),
		],
		editable: false,
		content: product.description,
	});

	const ImageSection = () => (
		<Center>
			<ProductImageCarousel product={product} variant='large' />
		</Center>
	);

	return (
		<Stack>
			<SimpleGrid cols={{ base: 1, sm: 2 }}>
				<ImageSection />
				<Stack align='flex-start'>
					<Tooltip label={product.title}>
						<Title order={2} lineClamp={1}>
							{product.title}
						</Title>
					</Tooltip>
					<Group wrap='nowrap' data-testid='isFavorite'>
						<ActionIcon
							size='sm'
							variant='transparent'
							radius='xs'
							loading={favoriteToggle.isPending}
							onClick={() => favoriteToggle.mutate()}
						>
							<icons.Heart
								color='red'
								fill={favorite ? 'red' : 'none'}
								data-testid='favoriteIcon'
							/>
						</ActionIcon>
						<Text size='md' fw={800} ml='xl'>
							{product.priceRange}
						</Text>
					</Group>
					<Group wrap='nowrap' align='flex-start'>
						<Text span fw='600'>
							{t('steps.products.labels.partNumber')}:
						</Text>
						<Text span>{product.itemId}</Text>
					</Group>
					<Group wrap='nowrap'>
						<Text span fw='600'>
							{t('steps.products.labels.unitOfMeasure')}:
						</Text>
						<Text span>{product.unitOfMeasure}</Text>
					</Group>
					<Group>
						<Text span fw='600'>
							{t('steps.products.labels.category', {
								count: product.categories.length,
							})}
							:
						</Text>
						{product.categories.map(c => (
							<Badge key={c} color='blue.7' size='sm' variant='light'>
								{c}
							</Badge>
						))}
					</Group>
					<Group wrap='nowrap'>
						<Text fw='600'>{t('steps.products.labels.availability')}:</Text>
						<StockIndicator
							itemState={product.itemState}
							isInStock={product.isInStock}
							estimatedInStockDate={product.estimatedInStockDate}
						/>
					</Group>
					<Group wrap='nowrap' data-testid='requiresAdminApproval'>
						{product.requiresApproval ? (
							<Group>
								<icons.Warning
									title={t('steps.products.labels.requiresApproval')}
									className={classes.approval}
									data-testid='warningIcon'
								/>
								<Text>{t('steps.products.labels.requiresApproval')}:</Text>
							</Group>
						) : (
							<></>
						)}
					</Group>
					<Group wrap='nowrap' data-testid='isConfidential'>
						{product.isConfidential ? (
							<>
								<icons.Lock
									title={t('steps.products.labels.confidential')}
									className={classes.confidential}
								/>
								<Text>{t('steps.products.labels.confidential')}</Text>
							</>
						) : null}
					</Group>
				</Stack>
			</SimpleGrid>
			<SimpleGrid cols={{ base: 1, sm: 2 }}>
				<Group wrap='nowrap' align='flex-start'>
					<Text>{t('steps.products.labels.description')}:</Text>
					{editor && (
						<RichTextEditor
							data-testid='description'
							editor={editor}
							classNames={{ root: classes.editor }}
						>
							<RichTextEditor.Content
								content={product.description}
								className={classes.description}
							/>
						</RichTextEditor>
					)}
				</Group>
				<Group align='flex-end' justify='flex-end'>
					<Button
						color={selected ? 'green' : 'blue'}
						data-testid='isSelected'
						w={100}
						onClick={x => {
							x.preventDefault();
							onChange(!selected, product);
						}}
					>
						{!selected
							? t('steps.products.links.add')
							: t('steps.products.links.remove')}
					</Button>
				</Group>
			</SimpleGrid>
		</Stack>
	);
}
