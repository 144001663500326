import { keepPreviousData, queryOptions } from '@tanstack/react-query';

import { getAllPrograms } from '@apple/features/program/api';

export const programsQueryOptions = queryOptions({
	queryKey: ['programs'],
	queryFn: () => getAllPrograms(),
	placeholderData: keepPreviousData,
	throwOnError: true,
});
