import { ActionIcon, Checkbox, Group, Title, Tooltip } from '@mantine/core';

import { icons } from '@apple/assets';

export type CardTitleProps<T> = {
	value: T;
	title?: string;
	isChecked: boolean;
	favoriteProps?: CardFavoriteProps<T>;
};

export type CardFavoriteProps<T> = {
	tooltip: string;
	favorite: boolean;
	favoriting: boolean;
	onFavoriteChange: CardChangeFn<T>;
};

export type CardChangeFn<T> = (checked: boolean, value: T) => Promise<void>;

export function CardTitle<T>({ value, title, isChecked, favoriteProps }: CardTitleProps<T>) {
	return (
		<Group wrap='nowrap' justify='space-between'>
			<Group gap='sm' wrap='nowrap'>
				<Checkbox
					checked={isChecked}
					tabIndex={-1}
					styles={{ input: { cursor: 'pointer' } }}
					onChange={() => {
						/* NOOP: Handled by button */
					}}
				/>
				{title && (
					<Title order={5} lineClamp={1}>
						{title}
					</Title>
				)}
			</Group>
			{favoriteProps && (
				<ActionIcon
					component='div'
					size='sm'
					variant='transparent'
					radius='xs'
					onClick={e => {
						e.stopPropagation();
						void favoriteProps.onFavoriteChange(!favoriteProps.favorite, value);
					}}
					data-testid='isFavorite'
					loading={favoriteProps.favoriting}
				>
					<Tooltip label={favoriteProps.tooltip}>
						<icons.Heart
							color='red'
							size='24'
							fill={favoriteProps.favorite ? 'red' : 'none'}
							data-testid='favoriteIcon'
						/>
					</Tooltip>
				</ActionIcon>
			)}
		</Group>
	);
}
