import { memo } from 'react';
import { Group, useMantineTheme } from '@mantine/core';
import type { GroupProps } from '@mantine/core';

import { NotificationMenu } from '@apple/features/announcements';
import { useAuthContext } from '@apple/features/auth';
import { CartMenu } from '@apple/features/cart';
import { AvatarMenu, ToggleColorSchemeButton } from '@apple/ui/shell';

function _TopbarUserMenu({
	variant,
	...groupProps
}: Omit<GroupProps, 'c'> & { variant: 'light' | 'dark' }) {
	const { authenticated } = useAuthContext();
	const { white, black } = useMantineTheme();

	const fontColor = variant === 'dark' ? black : white;

	return (
		<Group wrap='nowrap' gap={0} {...groupProps}>
			{authenticated && <CartMenu c={fontColor} />}
			{!authenticated && <ToggleColorSchemeButton />}
			{authenticated && <NotificationMenu c={fontColor} />}
			{authenticated && <AvatarMenu variant={variant === 'dark' ? 'light' : 'dark'} />}
		</Group>
	);
}

export const TopbarUserMenu = memo(_TopbarUserMenu);
