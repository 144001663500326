import type { ProductKey, ProductModel } from '@apple/features/product';
import type { PaginatedListDto } from '@apple/utils/pagination';

import type { ProgramId } from '../../program/models';

export interface ShopProductModel extends ProductModel {
	priceRange: string;
	isFavorite: boolean;
	isConfidential: boolean;
	requiresApproval: boolean;
	isInStock: boolean;
	estimatedInStockDate: string | null; // This is localized by the backend currently based on the users culture preference
	categories: string[];
}

export interface ProductFavoriteRequest {
	itemId: ProductKey;
	isFavorite: boolean;
}

export interface GetProductsRequest {
	favoritesOnly?: boolean;
	confidentialOnly?: boolean;
	selectedOnly?: boolean;
	searchText?: string;
	currentPage?: number;
	pageSize?: number;
	productCategory?: string;
	programId?: ProgramId;
	sortByOption?: ProductSortOptions;
}

export interface ProductListingResponse {
	products: PaginatedListDto<ShopProductModel>;
	availableProductCategories: string[];
	selectedProducts: string[];
}

export enum ProductSortOptions {
	ascending = 0,
	descending = 1,
	priceAscending = 2,
	priceDescending = 3,
	newToOld = 4,
	mostPopular = 5,
}
