import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	CloseButton,
	Group,
	MultiSelect,
	NumberInput,
	RangeSlider,
	rem,
	SegmentedControl,
	Select,
	Switch,
	TextInput,
	Tooltip,
} from '@mantine/core';
import { DateInput, DatePickerInput } from '@mantine/dates';
import { useDebouncedCallback } from '@mantine/hooks';
import { z } from 'zod';
import type { ComboboxData, ComboboxItem, RangeSliderProps } from '@mantine/core';
import type { Header, RowData } from '@tanstack/react-table';

import { useTranslation } from '@apple/lib/i18next';
import { useLogger } from '@apple/utils/logging/useLogger';

import { log } from '../utils/logger';
import type { ColumnFilterDef, FilterVariant } from '../types';

const booleanSchema = z.coerce.boolean().optional();
const numberSchema = z.coerce.number().optional();
const numberTupleSchema = z.tuple([z.coerce.number(), z.coerce.number()]).optional();
const dateSchema = z.coerce.date().optional();
const stringSchema = z.coerce.string().optional();
const stringArraySchema = z.coerce.string().array().optional();

export function FilterInput<TData, TValue>({
	header,
	label,
}: {
	header: Header<TData, TValue>;
	label: string;
}) {
	const [localValue, setLocalValue] = useState(header.column.getFilterValue());

	// Update local value when the filter value changes externally, for
	// example clearing all filters via `table.resetColumnFilters()`
	useEffect(() => {
		setLocalValue(header.column.getFilterValue());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [header.column.getFilterValue()]);

	const filterVariant = header.column.columnDef.filter?.variant;
	const setFilterValueDebounced = useDebouncedCallback(
		header.column.setFilterValue,
		header.column.columnDef.filter?.debounce ?? getFilterVariantDebounceTime(filterVariant),
	);

	const handleChange = useCallback(
		(value: unknown) => {
			setLocalValue(value);
			setFilterValueDebounced(value);
		},
		[setLocalValue, setFilterValueDebounced],
	);

	const handleClear = useCallback(() => {
		const table = header.getContext().table;
		const defaultValue = table.initialState.columnFilters.find(
			x => x.id === header.column.id,
		)?.value;
		setLocalValue(defaultValue);
		header.column.setFilterValue(defaultValue);
	}, [header]);

	const clearButton = header.column.getIsFiltered() && (
		<ClearButton
			onClear={() => {
				handleClear();
			}}
		/>
	);

	return filterVariant === 'select' || filterVariant === 'multi-select' ? (
		<SelectFilter
			id={header.column.id}
			type={filterVariant}
			value={localValue}
			filterOptions={header.column.columnDef.filter}
			options={Array.from(header.column.getFacetedUniqueValues().keys())}
			onChange={handleChange}
			onClear={handleClear}
			placeholder={header.column.columnDef.filter?.placeholder}
		/>
	) : filterVariant === 'switch' ? (
		<Switch
			id={header.column.id}
			name={label}
			checked={booleanSchema.parse(localValue)}
			onChange={value => handleChange(value.target.checked)}
			placeholder={header.column.columnDef.filter?.placeholder}
		/>
	) : filterVariant === 'false-only-switch' ? (
		<Switch
			id={header.column.id}
			name={label}
			checked={booleanSchema.parse(localValue === false)}
			onChange={value => handleChange(!value.target.checked ? undefined : false)}
			placeholder={header.column.columnDef.filter?.placeholder}
		/>
	) : filterVariant === 'segmented-switch' ? (
		<SegmentedControl
			id={header.column.id}
			name={label}
			value={stringSchema.parse(localValue) ?? ''}
			onChange={x => handleChange(x === '' ? undefined : x)}
			size='xs'
			fullWidth
			radius='sm'
			color='blue'
			data={Array.from(header.column.getFacetedUniqueValues().keys()).map(x => ({
				value: (x as string) ?? '',
				label: header.column.columnDef.filter?.getFilterDisplayValue?.(x) ?? (x as string),
			}))}
			styles={{
				root: {
					maxHeight: rem(24),
					padding: 0,
				},
				label: {
					height: rem(20),
				},
			}}
		/>
	) : filterVariant === 'date' ? (
		<DateInput
			id={header.column.id}
			w='100%'
			size='sm'
			clearable
			valueFormat='YYYY-MM-DD'
			value={!localValue ? null : dateSchema.parse(localValue)}
			onChange={handleChange}
		/>
	) : filterVariant === 'date-range' ? (
		<DatePickerInput
			id={header.column.id}
			type='range'
			w='100%'
			size='sm'
			clearable
			placeholder={header.column.columnDef.filter?.placeholder}
			valueFormat='YYYY-MM-DD'
			value={[
				dateSchema.parse((localValue as [string, string])?.[0]) ?? null,
				dateSchema.parse((localValue as [string, string])?.[1]) ?? null,
			]}
			onChange={([min, max]) => {
				// The user has cleard the date range
				if (min === null && max === null) {
					handleChange(undefined);
					return;
				}

				// The user hasn't selected an end date yet
				if (max === null) {
					return;
				}

				// TODO: Need to either set start and end fields separately or make the schema split up a tuple and map to start and end
				handleChange([min?.toISOString().slice(0, 10), max?.toISOString().slice(0, 10)]);
			}}
		/>
	) : filterVariant === 'number' ? (
		<NumberInput
			id={header.column.id}
			w='100%'
			placeholder={header.column.columnDef.filter?.placeholder}
			rightSection={clearButton}
			value={numberSchema.parse(localValue) ?? 0}
			onChange={handleChange}
		/>
	) : filterVariant === 'number-range' ? (
		<Group grow>
			<NumberInput
				id={header.column.id + '-min'}
				w='50%'
				placeholder={header.column.columnDef.filter?.placeholder}
				rightSection={clearButton}
				value={(localValue as [number, number])?.[0] ?? ''}
				onChange={value => handleChange((old: [number, number]) => [value, old?.[1]])}
			/>
			<NumberInput
				id={header.column.id + '-max'}
				w='50%'
				placeholder={header.column.columnDef.filter?.placeholder}
				rightSection={clearButton}
				value={(localValue as [number, number])?.[1] ?? ''}
				onChange={value => handleChange((old: [number, number]) => [old?.[0], value])}
			/>
		</Group>
	) : filterVariant === 'number-range-slider' ? (
		<RangeFilter
			id={header.column.id}
			value={numberTupleSchema.parse(localValue)}
			minMaxValues={header.column.getFacetedMinMaxValues()}
			onChange={handleChange}
		/>
	) : (
		<TextInput
			id={header.column.id}
			w='100%'
			placeholder={header.column.columnDef.filter?.placeholder}
			rightSection={clearButton}
			value={stringSchema.parse(localValue) ?? ''}
			onChange={x => handleChange(x.target.value)}
			onEmptied={() => handleChange('')}
		/>
	);
}

function RangeFilter({
	id,
	value,
	minMaxValues,
	onChange,
}: {
	id?: string;
	value?: [number, number];
	minMaxValues?: [number, number];
	onChange?: (value: [number, number]) => void;
}) {
	const minMaxProps = useMemo<Partial<RangeSliderProps>>(() => {
		const values = {
			min: minMaxValues?.[0] ?? 0,
			max: minMaxValues?.[1] ?? 100,
		};

		values.max = Math.max(values.min, values.max);
		values.min = Math.min(values.min, values.max);

		if (values.min === values.max) {
			values.max = values.min + 1;
		}

		return values;
	}, [minMaxValues]);

	const valueProps = useMemo<Partial<RangeSliderProps>>(() => {
		let minMax: [number, number] = [minMaxProps.min ?? 0, minMaxProps.max ?? 100];

		// Check if filterValue is a valid tuple of numbers
		if (
			Array.isArray(value) &&
			value.length === 2 &&
			typeof value[0] === 'number' &&
			typeof value[1] === 'number'
		) {
			minMax = [value[0], value[1]];
		}

		return {
			value: minMax,
		};
	}, [value, minMaxProps]);

	useLogger({
		log,
		name: `Column ${id} | RangeFilter`,
		props: [
			{
				id,
				value,
				minMaxValues,
				minMaxProps,
				valueProps,
			},
		],
	});

	return (
		<RangeSlider
			id={id}
			w='100%'
			onChange={onChange}
			step={1}
			minRange={1}
			{...minMaxProps}
			{...valueProps}
		/>
	);
}

function SelectFilter<TData extends RowData, TValue = unknown>({
	id,
	type,
	value: filterValue,
	options,
	filterOptions,
	placeholder,
	onChange,
	onClear,
}: {
	id: string;
	type: 'select' | 'multi-select';
	value: TValue | TValue[];
	options: unknown[];
	filterOptions?: ColumnFilterDef<TData, TValue>;
	placeholder?: string;
	onChange: (value: TValue | TValue[]) => void;
	onClear: () => void;
}) {
	const sortedUniqueValues = useMemo<ComboboxData>(
		() =>
			options.sort().map(
				x =>
					({
						value: String(x),
						label: filterOptions?.getFilterDisplayValue?.(x) ?? String(x),
					}) satisfies ComboboxItem,
			),
		[filterOptions, options],
	);
	const value = useMemo(
		() => (type !== 'select' ? null : (stringSchema.parse(filterValue) ?? null)),
		[type, filterValue],
	);
	const values = useMemo(
		() => (type !== 'multi-select' ? [] : stringArraySchema.parse(filterValue)),
		[type, filterValue],
	);

	useLogger({
		log,
		name: `Column ${id} | SelectFilter`,
		props: [
			{
				id,
				type,
				rawValue: filterValue,
				parsedValue: value,
				filterOptions,
				rawOptions: options,
				parsedOptions: sortedUniqueValues,
			},
		],
	});

	return type === 'select' ? (
		<Select
			placeholder={placeholder}
			id={id}
			w='100%'
			allowDeselect
			checkIconPosition='right'
			clearable
			value={value}
			onChange={val =>
				!val
					? onClear()
					: onChange(filterOptions?.filterFromString?.(val) ?? (val as TValue))
			}
			onClear={onClear}
			data={sortedUniqueValues}
		/>
	) : (
		<MultiSelect
			placeholder={placeholder}
			id={id}
			w='100%'
			checkIconPosition='right'
			clearable
			value={values}
			data={sortedUniqueValues}
			onChange={stringValues =>
				onChange(
					stringValues.map(
						stringValue =>
							filterOptions?.filterFromString?.(stringValue) ??
							(stringValue as TValue),
					),
				)
			}
			onClear={onClear}
		/>
	);
}

function ClearButton({ onClear }: { onClear?: () => void }) {
	const { t } = useTranslation('controls');
	const label = t('dataTable.resetFilters');
	return (
		<Tooltip label={label}>
			<CloseButton onClick={onClear} aria-label={label} variant='transparent' size='sm' />
		</Tooltip>
	);
}

function getFilterVariantDebounceTime(filterVariant: FilterVariant | undefined) {
	switch (filterVariant) {
		case 'switch':
		case 'date':
		case 'date-range':
			return 50;
		case 'select':
		case 'multi-select':
			return 100;
		case 'number':
		case 'text':
		case 'number-range':
		case 'number-range-slider':
			return 500;
		default:
			return 200;
	}
}
