import { memo } from 'react';
import { Group, rem, Title } from '@mantine/core';
import type { ReactNode } from 'react';

export interface ToolbarProps {
	title: string;
	leftSection?: () => ReactNode;
	rightSection?: () => ReactNode;
}

export const Toolbar = memo(({ title, leftSection, rightSection }: ToolbarProps) => {
	return (
		<Group
			px='lg'
			py={0}
			mb='md'
			mt='xs'
			bg='light-dark(white, var(--mantine-color-dark-7))'
			align='center'
			justify='space-between'
			mih={rem(52)}
			mah={rem(52)}
			w='100%'
		>
			<Group>
				{leftSection && leftSection()}
				<Title order={3} fw={600}>
					{title}
				</Title>
			</Group>
			<Group gap={0}>{rightSection && rightSection()}</Group>
		</Group>
	);
});

Toolbar.displayName = 'Toolbar';
