import { getEnv } from '@apple/utils/config/env';

import type { RoleName } from '../models/roles';
import type { Permission, ProfileDto } from '../models/user';

const env = getEnv();

export type AuthOptions = AuthRequirements<{
	returnUrl?: string;
}>;

export type AuthRequirements<T> = T &
	Partial<{
		requireAuth: boolean;
		requireRole: RoleName;
		requireRoles: RoleName[];
		requirePermission: Permission;
		requirePermissions: Permission[];
	}>;

export function isAdmin(profile: ProfileDto | null | undefined) {
	if (!profile) {
		return false;
	}

	return profile.roles.includes(env.APPLE_ADMIN_ROLE);
}

export function hasRoles(profile: ProfileDto | null | undefined, roles: RoleName[]) {
	if (!profile) {
		return false;
	}
	return isAdmin(profile) || roles.every(role => profile.roles.includes(role));
}

export function hasPermissions(profile: ProfileDto | null | undefined, permissions: Permission[]) {
	if (!profile) {
		return false;
	}

	return (
		hasRoles(profile, [env.APPLE_ADMIN_ROLE]) ||
		permissions.every(permission => profile.permissions.includes(permission))
	);
}

export function isAuthorized(
	profile: ProfileDto | null | undefined,
	requirements: AuthRequirements<unknown>,
) {
	if (!profile && requirements.requireAuth) {
		return false;
	}

	if (requirements.requireRole) {
		return hasRoles(profile, [requirements.requireRole]);
	}

	if (requirements.requireRoles) {
		return hasRoles(profile, requirements.requireRoles);
	}

	if (requirements.requirePermission) {
		return hasPermissions(profile, [requirements.requirePermission]);
	}

	if (requirements.requirePermissions) {
		return hasPermissions(profile, requirements.requirePermissions);
	}

	return true;
}

export function filterByPermissions<T>(
	profile: ProfileDto | null | undefined,
	items: AuthRequirements<T>[],
): T[] {
	return items.filter(item => isAuthorized(profile, item));
}
