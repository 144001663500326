import { useQuery } from '@tanstack/react-query';

import { getActiveAnnouncementsQueryOptions } from '../queries';
import type { AnnouncementList } from '../models';

type AnnouncementsResponse =
	| { isLoading: true }
	| { isLoading: false; response: AnnouncementList; error: null }
	| { isLoading: false; error: Error };

export function useActiveAnnouncements(): AnnouncementsResponse {
	const { data: response, error, isLoading } = useQuery(getActiveAnnouncementsQueryOptions);

	if (isLoading) {
		return { isLoading: true };
	}

	if (error) {
		return { isLoading: false, error };
	}

	if (!response) {
		throw new Error('Failed to get active announcements for user');
	}

	return {
		isLoading,
		error,
		response,
	};
}
