import { useCallback, useEffect, useState } from 'react';
import { Combobox, ScrollArea, TextInput, useCombobox } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { getLogger } from 'loglevel';

import { icons } from '@apple/assets';
import { useTranslation } from '@apple/lib/i18next';

const log = getLogger('product-autocomplete');

export type ProductAutocompleteProps<T> = {
	onSearch: (searchText: string, excludedPartNumbers: string[]) => Promise<T[]>;
	keySelector: (product: T) => string;
	displayTextSelector: (product: T) => string;
	renderOptions: (products: T[]) => JSX.Element[];
	excludedPartNumbers: string[];
	value: T | undefined;
	onChange: (product: T | undefined) => void;
	minimumSearchLength?: number;
	debounceTime?: number;
};

export function ProductAutocomplete<T>({
	onSearch,
	keySelector,
	displayTextSelector,
	renderOptions,
	excludedPartNumbers,
	value,
	onChange,
	minimumSearchLength = 3,
	debounceTime = 400,
}: ProductAutocompleteProps<T>) {
	const { t } = useTranslation('common');
	const [autocompleteProducts, setAutocompleteProducts] = useState<T[]>([]);
	const [searchTerm, setSearchTerm] = useDebouncedState('', debounceTime);
	const [lastSearchTerm, setLastSearchTerm] = useState('');
	const [displayText, setDisplayText] = useState('');
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const selector = useCombobox({
		onDropdownClose: () => {
			setIsDropdownOpen(false);
			selector.resetSelectedOption();
		},
		onDropdownOpen: () => setIsDropdownOpen(true),
	});

	useEffect(() => {
		if (searchTerm.trim().length < minimumSearchLength || searchTerm === lastSearchTerm) {
			return;
		}

		setLastSearchTerm(searchTerm);

		async function search() {
			try {
				const products = await onSearch(searchTerm, excludedPartNumbers);
				setAutocompleteProducts(products);
				if (!isDropdownOpen) selector.toggleDropdown();
			} catch (error) {
				log.error(error);
				notifications.show({
					color: 'red',
					message: t('error.generic'),
					icon: <icons.Error />,
				});
			}
		}

		void search();
	}, [
		isDropdownOpen,
		lastSearchTerm,
		excludedPartNumbers,
		searchTerm,
		selector,
		setLastSearchTerm,
		onSearch,
		t,
		minimumSearchLength,
	]);

	const productDropdownOptions = renderOptions(autocompleteProducts);

	const handleOptionSubmit = useCallback(
		(value: string) => {
			if (value === '0') {
				return;
			}

			const product = autocompleteProducts.find(x => keySelector(x) === value);
			if (product === undefined) {
				return;
			}

			if (excludedPartNumbers.some(x => x === keySelector(product))) {
				setAutocompleteProducts(currentProducts =>
					currentProducts.filter(x => keySelector(x) !== keySelector(product)),
				);
				return;
			}

			onChange(product);
			setDisplayText(displayTextSelector(product));
			selector.toggleDropdown();
			setIsDropdownOpen(false);
		},
		[
			autocompleteProducts,
			displayTextSelector,
			excludedPartNumbers,
			keySelector,
			onChange,
			selector,
		],
	);

	const handleClearSelection = useCallback(() => {
		setLastSearchTerm('');
		setSearchTerm('');
		onChange(undefined);
	}, [onChange, setSearchTerm]);

	const handleInputClick = useCallback(() => {
		if (!isDropdownOpen && autocompleteProducts.length > 0) {
			selector.toggleDropdown();
			setIsDropdownOpen(true);
		}
	}, [autocompleteProducts.length, isDropdownOpen, selector]);

	const dropdownOptions =
		autocompleteProducts.length > 0 ? (
			productDropdownOptions
		) : (
			<Combobox.Option value='0' key='0'>
				{t('productAutocomplete.notFound', { searchTerm })}
			</Combobox.Option>
		);

	return (
		<>
			{value !== undefined ? (
				<TextInput
					value={displayText}
					readOnly
					leftSection={<icons.Close color='red' onClick={handleClearSelection} />}
				/>
			) : (
				<Combobox store={selector} onOptionSubmit={handleOptionSubmit}>
					<Combobox.Target>
						<TextInput
							onClick={handleInputClick}
							placeholder={t('productAutocomplete.placeholder')}
							defaultValue=''
							onChange={event => setSearchTerm(event.currentTarget.value)}
							data-testid='autocomplete-input'
						/>
					</Combobox.Target>
					<Combobox.Dropdown>
						<ScrollArea.Autosize type='scroll' mah={200}>
							<Combobox.Options>{dropdownOptions}</Combobox.Options>
						</ScrollArea.Autosize>
					</Combobox.Dropdown>
				</Combobox>
			)}
		</>
	);
}
