import { memo, useCallback } from 'react';
import { Combobox, useCombobox } from '@mantine/core';

import { icons } from '@apple/assets';
import { ProductSortOptions } from '@apple/features/product/models/product.models';
import { useTranslation } from '@apple/lib/i18next';
import { FilterBarDropdown } from '@apple/ui/data-table/controls/FilterBarDropdown';

import type { ProductFilters } from './ProductFilterBar';

export interface ProductSortDropdownProps {
	hasMultiplePlants: boolean;
	filters: ProductFilters;
	onFiltersChanged: (filters: ProductFilters) => void;
}

export const ProductSortDropdown = memo<ProductSortDropdownProps>(
	({ hasMultiplePlants, filters, onFiltersChanged }: ProductSortDropdownProps) => {
		const { t } = useTranslation('shop');

		const sortOptionSelector = useCombobox({
			onDropdownClose: () => sortOptionSelector.resetSelectedOption(),
		});

		let sortOptions = [
			<Combobox.Option value={`${ProductSortOptions.newToOld}`} key='sort-newToOld'>
				{t('steps.products.filterBar.sort.newToOld')}
			</Combobox.Option>,
			<Combobox.Option value={`${ProductSortOptions.ascending}`} key='sort-ascending'>
				{t('steps.products.filterBar.sort.ascending')}
			</Combobox.Option>,
			<Combobox.Option value={`${ProductSortOptions.descending}`} key='sort-descending'>
				{t('steps.products.filterBar.sort.descending')}
			</Combobox.Option>,
		];

		if (!hasMultiplePlants) {
			sortOptions = [
				<Combobox.Option value={`${ProductSortOptions.mostPopular}`} key='sort-mostPopular'>
					{t('steps.products.filterBar.sort.mostPopular')}
				</Combobox.Option>,
				...sortOptions,
				<Combobox.Option
					value={`${ProductSortOptions.priceAscending}`}
					key='sort-priceAscending'
				>
					{t('steps.products.filterBar.sort.priceAscending')}
				</Combobox.Option>,
				<Combobox.Option
					value={`${ProductSortOptions.priceDescending}`}
					key='sort-priceDescending'
				>
					{t('steps.products.filterBar.sort.priceDescending')}
				</Combobox.Option>,
			];
		}

		const handleSortOptionSelected = useCallback(
			(sortOption: string) => {
				onFiltersChanged({
					...filters,
					sortByOption: parseInt(sortOption),
				});
			},
			[filters, onFiltersChanged],
		);

		return (
			<FilterBarDropdown
				options={sortOptions}
				store={sortOptionSelector}
				onOptionSubmit={handleSortOptionSelected}
				targetIcon={<icons.SortIcon />}
				variant='transparent'
				toolTip={t('steps.products.filterBar.sort.label')}
				testId='sortby-selector'
			/>
		);
	},
);
