import { Stack, Text } from '@mantine/core';

import type { LocationListing } from '@apple/features/location';

export interface LocationAddressProps {
	location: LocationListing;
}

export function LocationAddress({ location }: LocationAddressProps) {
	return (
		<Stack gap='2'>
			<Text size='sm'>{location.addressLine1 ?? ''}</Text>
			<Text size='sm'>
				<Text span>{location.city}</Text>
				<Text span>{location.stateOrProvince || location.postalCode ? ',' : ''}</Text>
				<Text span>
					{location.stateOrProvince ? location.stateOrProvince : location.postalCode}
				</Text>
			</Text>
			<Text size='sm'>{location.countryName}</Text>
		</Stack>
	);
}
